import React from "react";
import { connect } from 'react-redux';

import { 
    dispatchTemplateSetDraft,
    dispatchTemplateSetDocument,
    dispatchLoadDraftList,
    dispatchTemplateAfterSave,
} from  '../../store/actions';

import { formatDateMySQL } from '../funcDate'

import { Button, Dimmer, Portal, Segment, Header, Message, Progress } from 'semantic-ui-react'

const JSZip = require("jszip");

const checkValues=(data, strFunc)=>{    
    const errors=[]
    Object.entries(data.pages).forEach(([pageName, page])=>{
        Object.entries(page.fields).forEach(([field, options])=>{
            const value = data.values[field]
            if (options.type==='table' && value){
                value.forEach((row, i)=>{
                    Object.entries(options.cols).forEach(([col, colOptions])=>{
                        if (!colOptions.required || row[col]) return;
                        errors.push({field: field, options: options, col: col, row: i+1})
                    })
                })
            }
            if (!options.required) return;
            if (options.type==='user_routes' && value && (!value.users || !value.users.length))  
                errors.push({field: field, options: options})
            else if (!value || Array.isArray(value) && !value.length )
                    errors.push({field: field, options: options})
        })
    })
    const result=[];    
    errors.forEach(e=>{
        if (e.col) result.push(`В строке №${e.row} таблицы '${e.options.label || e.field}' поле '${e.options.cols[e.col].label}'  не заполнено`)
            else result.push(`Поле '${e.options.label}' не заполнено`)
    })
    
    let resFunc = null;
    try {
        const func = new Function('data', 'fields', strFunc);
        resFunc = func(data.values)
    } catch (err) {
        alert(err)
    }
    return [...result, ...(resFunc || [])]
}

const createDocument=(data, call)=>{    
    const result = {}
    result.template = {guid: data.guid, ...data.template};
    result.guid = data.guidDoc;
    result.values = data.values || {};
    result.route = data.route;
    result.document = data.document;
    result.dateSave = (new Date()).toISOString();
    
    if (data.set_print_data){        
        try{
            const func = new Function('data', 'lists', data.set_print_data);
            result.print_data = func(data.values, data.lists) || null;
            
        } catch(err){
            alert(err)
            return null;
        }
    }
    
    const zip = new JSZip();
    zip.file("data.json", JSON.stringify(result));
    return zip.generateAsync({type: 'arraybuffer', streamFiles: false})
}

const mapStateToProps = (store, props) => {
    const resultSave = store.templates.edit.resultSave || {}
    return {
        loading: store.loading,
        isMobile: store.media==='mobile' || store.media==='tablet',
        open: true,
        data: store.templates.edit,
        resultSaveId: resultSave.request_id || resultSave.message || '',
        resultMessage: resultSave.message || null,
        resultSave: resultSave.result || null,        
        user: store.user.data.guid,
        userName: store.user.data.name,
        userEmail: store.user.data.email,
        isNew: !store.templates.edit.dateSave,
        urlUploadDraft: store.templates.edit.urls.draft_upload,
        urlUploadDocument: store.templates.edit.urls.document_upload,
        checkFunc: store.templates.edit.check,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      setDraft: (url, content, guid, template, user)=>dispatch(dispatchTemplateSetDraft(url, content, guid, template, user)),
      setDocument: (url, content, guid, template, user)=>dispatch(dispatchTemplateSetDocument(url, content, guid, template, user)),      
      loadDrafts: ()=>dispatch(dispatchLoadDraftList()),
      clearResult: ()=>dispatch(dispatchTemplateAfterSave()),
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocSave=reduxConnect(props=> { 
    const [errors, setErrors] = React.useState({checked: false, list:[]});
    const [enableSave, setEnableSave] = React.useState(false);
    //console.log(props.resultSaveId)
    React.useEffect(()=> {
        //console.log('begin', props.type);        
        if (props.type==='document') setErrors({checked: true, list: checkValues(props.data, props.checkFunc)})
            else setEnableSave(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, []);
     
     React.useEffect(()=> {
         if (errors.checked) setEnableSave(!errors.list.length)
         // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, [errors.checked]);
     
     React.useEffect(()=> {
         if (enableSave) {
            const prom = createDocument(props.data)
            if (!prom){
                setEnableSave(false);
                setErrors({checked: true, list:['Ошибка при создании документа']})
                return
            }
            prom.then(data=>{
                const meta={};
                meta['Document-Name'] = encodeURI(props.data.name);
                meta['Document-Author-Guid'] = props.user;
                meta['Document-Author-Name'] = encodeURI(props.userName);
                meta['Document-Author-Email'] = props.userEmail;
                if (props.type==='document')  {                    
                    props.setDocument(props.urlUploadDocument, data, props.data.guidDoc, props.data.guid, props.user)
                }
                else props.setDraft(props.urlUploadDraft, data, props.data.guidDoc, props.data.guid, props.user)
            })            
         }
         //console.log(enableSave)
         // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, [enableSave]);
     
     React.useEffect(()=> {
         if (!props.resultSaveId) return;
         if (props.resultSave && errors.checked){
             props.onSuccess();          
         }         
         if (!props.resultSave){
             setErrors({checked: true, list:[props.resultMessage]})
         }else{
             props.loadDrafts();
             props.onSuccess();
             props.clearResult();             
         }                  
     }, [props.resultSaveId]);
    
    const handleClose=()=>{
        props.clearResult();
        props.onError();
    }
    
    return  <div style={{position: 'fixed',  opacity: 0.3,
                        top: '0',left: '0',bottom: '0',right: '0',
                        zIndex: '1100', display: props.open ? 'block' : 'none'}}>
            <Dimmer active={props.open}/>
            <Portal open={props.open} closeOnPortalMouseLeave={false} closeOnDocumentClick={false}>
            <Segment
              style={{
                left: '10%',
                position: 'fixed',
                top: '20%',
                width: '80%',
                zIndex: 1120,
              }}
            >
              <Header>Сохранение</Header>
              {errors.list.length>0 && <Message error header='Ошибки, препятствующие сохранению:' list={errors.list} />}
              {errors.list.length>0 && <Button content="Закрыть"  onClick={handleClose} />}
              {errors.list.length===0 && <Progress  percent={99} color='yellow' active/>}
            </Segment>
          </Portal></div>

})