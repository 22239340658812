import React from "react";
import { Portal, Segment, Header, Button, Dimmer } from 'semantic-ui-react'

export const Confirm=(props)=> { 
    
    return  <div style={{position: 'fixed',  opacity: 0.3,
                        top: '0',left: '0',bottom: '0',right: '0',
                        zIndex: '10', display: props.open ? 'block' : 'none'}}>
            <Dimmer active={props.open}/>
            <Portal onClose={props.onCancel} open={props.open}>
            <Segment
              style={{
                left: '40%',
                position: 'fixed',
                top: '40%',
                zIndex: 1500,
              }}
            >
             { props.header && <Header>{props.header}</Header>}
              <p>{props.content}</p>
              <Button
                content={props.cancelButton}                
                onClick={props.onCancel}
              />
              <Button
                content={props.confirmButton}
                color='blue'
                onClick={props.onConfirm}
              />
            </Segment>
          </Portal></div>

}