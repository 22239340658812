import React from "react";
import { connect } from 'react-redux';
import { useLocation , useNavigate } from 'react-router-dom';
import { dispatchSetUserProps, dispatchQRCodeTokenLogin, } from '../store/actions';
import { isDemo } from '../store/const';

import HomepageLayout  from './HomepageLayout';
import { MainFrame } from './Main'; 

const mapStateToProps = (store) => {    
    return {
        
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      setUserData: (data)=>dispatch(dispatchSetUserProps(data)), 
      tokenLogin: (token)=> dispatch(dispatchQRCodeTokenLogin(token)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const FrontPage=reduxConnect(props=> { 
      const runNavigate = useNavigate();
      const currentLocation = useLocation();
      
      React.useEffect(()=>{
         const params = (new URL('https://host/'+(currentLocation.search || currentLocation.hash).replace('#','?'))).searchParams;
         const paramsState = params.get('state'); 
         if (paramsState){
             const paramsStates = paramsState.split('@');
             const appPath = paramsStates[1].split('.')[0];
             const openID = {
                 name: paramsStates[0], 
                 token: params.get('access_token'), 
                 token_type: params.get('token_type'),
                 user_id: params.get('user_id'),  // For VK               
             }
             props.setUserData({path:appPath, openID: openID})
             //runNavigate(appPath+'/logout');             
             runNavigate( isDemo() ? appPath : '/');             
             
             
             //runNavigate(-3);
         }
         const paramsToken = params.get('token'); 
         
         if(paramsToken){
             props.tokenLogin(paramsToken)
         }
          
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [currentLocation.search])
     
     
     if (!isDemo()){
         return <MainFrame />
     }
     
     return <div className='div-scroll'><HomepageLayout/></div>
})