import React from "react";
import { connect } from 'react-redux';
import { dispatchSignDocument, 
dispatchSignCryptoProDocument,
dispatchRejectSignDocument, 
dispatchCancelSignDocument,
dispatchReturnSignDocument,
 } from '../store/actions';

import { Button, Message, Form, Modal, Icon } from 'semantic-ui-react'

import { SelectCertificate } from './SelectCertificate';

const mapStateToProps = (store) => {        
    return {
        successSignDocument: store.docs.successSignDocument, 
        loading: store.loading, 
        cryptoPro: store.cryptoPro.system && store.cryptoPro.plugin,
        certId: store.cryptoPro.certId,
        certCount: store.cryptoPro.certificates.length,
        media: store.media,
        size: store.media=='desktop' ? null : 'large',
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      signDocument: (guid, passphrase)=> dispatch(dispatchSignDocument(guid, passphrase)),
      signCryptoProDocument: (guid, passphrase)=> dispatch(dispatchSignCryptoProDocument(guid, passphrase)),
      rejectSignDocument: (guid, description)=> dispatch(dispatchRejectSignDocument([guid], description)),
      cancelSignDocument: (guid, description)=> dispatch(dispatchCancelSignDocument([guid], description)),
      returnSignDocument: (guid, description)=> dispatch(dispatchReturnSignDocument([guid], description)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const SignDocument=reduxConnect(props=> { 
     const [passphrase, setPassphrase]= React.useState('')
     const [description, setDescription]= React.useState('')
     const [error, setError]= React.useState('')
     
     const isMobile = props.media==='mobile' || props.media==='tablet';
     const isSign = !props.reject && !props.cancel && !props.returnDoc;
     
     React.useEffect(()=> {
        if (props.successSignDocument==='done') props.onClose('done')
          else if(props.successSignDocument) setError(props.successSignDocument)    
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, [props.successSignDocument]);
     
     const save=()=>{       
         if (isSign && props.guid) {
            if(props.isReinforced) props.signCryptoProDocument([props.guid], passphrase) 
                else props.signDocument([props.guid], passphrase)
         }else if (isSign && props.selected) {
            if(props.isReinforced) props.signCryptoProDocument(props.selected, passphrase) 
                else props.signDocument(props.selected, passphrase)
         }
         else if(props.reject) props.rejectSignDocument(props.guid, description)
         else if(props.cancel) props.cancelSignDocument(props.guid, description)
         else if(props.returnDoc) props.returnSignDocument(props.guid, description)
     }
     
     const handleChangePassphrase=(e, {name, value})=>{         
         setPassphrase(value)
     }
    
    let winHead = 'Подписание документа';
    let textLabel = '';
    let btnColor = 'green';
    
    if (props.reject) {
        winHead = 'Отказ от подписания';
        textLabel = 'Укажите причину отказа от подписания';
        btnColor = 'red'
    }else if (props.cancel){
        winHead = 'Аннулирование'
        textLabel = 'Укажите причину аннулирования';
        btnColor = 'red'
    }else if (props.returnDoc){
        winHead = 'Возврат в работу'
        textLabel = 'Укажите причину возврата в работу';
        btnColor = 'blue'
    }

    const btnText = isSign ? 'Подписать' : 'Отправить';
    const btnIcon = isSign ? 'pencil' : 'send';
    
    const winStyle={}
    if (isMobile) winStyle.top='5%';
    
    return <Modal size='mini' style={winStyle}
      onClose={() => props.onClose()}
      open={true}
    >    
      <Modal.Header><Icon name='certificate' color={btnColor}/>  {winHead}</Modal.Header>
      <Modal.Content>        
         <Form loading={props.loading} size={props.size}>  
             {props.isReinforced && isSign && <Form.Input label='Сертификат'> <SelectCertificate /> </Form.Input>}
         
             {isSign && !props.isReinforced && <Form.Input type='password' 
                label='Парольная фраза' placeholder='**********' autoComplete="one-time-code" 
                name='passphrase' value={passphrase} onChange={(e, {value})=>setPassphrase(value)}/>}
             {!isSign  && <Form.TextArea label={textLabel} autoComplete="one-time-code"
                name='description' value={description} onChange={(e, {value})=>setDescription(value)}/>}
          </Form>
          {error && <Message error content={error}/>}
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => props.onClose()} disabled={props.loading} size={props.size}> 
          Отмена
        </Button>
        <Button type='submit' size={props.size}
            disabled={isSign && !props.isReinforced && !passphrase 
                || !isSign && !description 
                || props.loading 
                || isSign && props.isReinforced && (!props.certId || props.certCount===0)}
          content={btnText}
          labelPosition='left'
          icon={btnIcon}
          onClick={save}
          color={btnColor}
        />
      </Modal.Actions>
      
    </Modal>

})