import {
    checkIsValidSystemSetup, 
    checkPlugin,
    outputError,
    getCertificates,
    STORE_TYPE,
    sign
    
  } from '@astral/cryptopro-cades';
  
import {sendRequest} from './requests'

import { dispatchLoading,
    actionCryptoProCheckSystem, 
    dispatchCryptoProSetData,
    actionCryptoProGetCertificates,
    dispatchCryptoProGetCertificates,
    actionSignCryptoProDocument,
    dispatchSetSignCryptoPro,
 } from './actions';


import { formatDateMySQL } from '../components/funcDate';

/**
* Проверить систему.
*/
async function checkSystem(callback) {
    try {
      await checkIsValidSystemSetup();
      callback(true);
    } catch (error) {
      outputError(error);
      callback(false, error.toString());
    }
}

/**
* Проверить криптопро браузер плагин.
*/
async function checkCryptoProPlugin(callback) {
    try {
      await checkPlugin();
      callback(true)
    } catch (error) {
      outputError(error);
      callback(false, error.toString());
    }
}

async function fetchCertificates(callback) {
  try {
    const fetchedCertificates = await getCertificates(STORE_TYPE.ALL);
    callback(true, fetchedCertificates);
  } catch (error) {
    outputError(error);
    callback(false, error.toString());
  }
}

const signData = async (selectedCertificate, buffer, callback)=>{ 

    try {
      const sig = await sign(
        selectedCertificate,
        buffer, // массив байт либо массив байт в формате Base64 строки
        true,  //Не присоединять подпись
        false, //Не включать в результат всю цепочку сертификатов
        false, //не проводить валидацию сертификатов
      );

      //dowloadFile(await convertBase64toBlob(sig), selectedFile.name + '.sig');
      callback(true, sig);
    } catch (error) {
      outputError(error);
      callback(false, error.toString());
    }
  };


const signUrl=(certificate, url, callback)=>{
    fetch(url, {method: 'GET'})
        .then(response => {
            if (response.status!==200) {
                callback(false, response.status)
                return
            }
            return response.arrayBuffer();
         })
        .then(buffer => {
            signData(certificate, buffer, callback)
        })
        .catch(e => callback(false, String(e)));
}


const getUrls=(docs, path, token)=>{
    return new Promise((resolve, reject) => {
        sendRequest(path, token, 'doc-urls', docs, (code, res)=>{
            if (code===200 && res.result) resolve(res.docs)
                reject(res)
        })
        
    })
}


export const cryptoproMiddleware=(store)=> {  
    
  return next => action => {
    if (action.type===actionCryptoProCheckSystem){         
        checkCryptoProPlugin((res, error)=>{
            store.dispatch(dispatchCryptoProSetData({system: res, error: error}))
            if (res) checkSystem((res, error)=>{
                        store.dispatch(dispatchCryptoProSetData({plugin: res, error: error}));
                        store.dispatch(dispatchCryptoProGetCertificates());
                    })
        })         
    }
    if (action.type===actionCryptoProGetCertificates){ 
        store.dispatch(dispatchCryptoProSetData({findCertificates: true}));    
        fetchCertificates((res, data)=>{
            const result = {findCertificates: false, certificates:[]}
            if (res) {
                data.forEach(e=>{
                    if ( (new Date(e.notAfter))> (new Date()) ) 
                              result.certificates.push(e)
                })
                
            } else result.error = data;
            store.dispatch(dispatchCryptoProSetData(result));
        })
    }  
    
    if (action.type===actionSignCryptoProDocument){
        store.dispatch(dispatchLoading(true));
        //console.log(action)
        const state = store.getState()
        const {token, path} = state.user;
        
        getUrls(action.docs, path, token).then(docUrls=>{
        
            const certificate = state.cryptoPro.certificates.find(e=>e.subjectKeyId===state.cryptoPro.certId)
            
            const promises=[]
            action.docs.forEach(guid=>{
                const url = docUrls[guid];
                if (!url) return null;
                
                const promise1 = new Promise((resolve, reject) => {
                    signUrl(certificate, url, (result, data)=> resolve({result: result, guid:guid, isView:false, data:data}))
                })
                promises.push(promise1)
                
                /*if (doc.url_view){
                    const promise2 = new Promise((resolve, reject) => {
                        signUrl(certificate, doc.url_view, (result, data)=> resolve({result: result, guid:guid, isView:true, data:data}))
                    })
                    promises.push(promise2)
                }*/
                
            })
            
            Promise.allSettled(promises).then(res=>{
                const signs = {};
                res.forEach(e=>{
                    if(e.value.result) {
                        if (!signs[e.value.guid])  signs[e.value.guid] = {doc:e.value.guid, date: formatDateMySQL(new Date(), true)}
                        if (!e.value.isView) signs[e.value.guid].sign = e.value.data
                            else signs[e.value.guid].sign_view = e.value.data
                    }
                })
                
                if (!Object.keys(signs).length){
                    store.dispatch(dispatchLoading(false));
                    return
                }
                store.dispatch(dispatchSetSignCryptoPro(signs));
            })
        })
        
    }
    
    
    const returnValue = next(action);
    return returnValue;
  }
}