import React from "react";
import { connect } from 'react-redux';
import { dispatchWebauthnRegOptions, dispatchWebauthnAuth, dispatchSetWebauthnProps, dispatchSetWebauthnUser } from '../store/actions';
import { browserSupportsWebAuthn, platformAuthenticatorIsAvailable  } from '@simplewebauthn/browser';



import { Form } from 'semantic-ui-react'


const mapStateToProps = (store, props) => { 
    return {
        enabled: store.webauthn.enabled,
        user_guid: store.webauthn.guid,
        regMode: props.mode==='reg',
        authMode: props.mode==='auth',        
        loginData: store.webauthn.login, 
        isClose: !store.webauthn.result, 
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      getOptions: ()=> dispatch(dispatchWebauthnRegOptions()),
      auth: (data)=> dispatch(dispatchWebauthnAuth(data)),
      disable: ()=>dispatch(dispatchSetWebauthnProps({enabled: false})),
      clear: ()=>dispatch(dispatchSetWebauthnProps({login: null})),
      setUser: (data)=>dispatch(dispatchSetWebauthnUser(data)),
      
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const Webauthn=reduxConnect(props=> { 
    const [isSupportBrowser, setSupportBrowser]= React.useState(false);
    const [isSupportPlatform, setSupportPlatform] = React.useState(false);
    
    React.useEffect(()=> {
        setSupportBrowser(browserSupportsWebAuthn());
        
        if (!props.authMode) return
        props.auth({options:true, guid: props.user_guid})
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, []);
     
     React.useEffect(()=> {
        if (isSupportBrowser)  platformAuthenticatorIsAvailable().then(res=>setSupportPlatform(res))
     }, [isSupportBrowser]);
     
     React.useEffect(()=> {
        //if (props.isClose) props.onClose()  
     }, [props.isClose]);
 
     React.useEffect(()=> {
        if(!props.loginData) return;
        props.setUser(props.loginData);
        props.clear(); 
        if (props.mode==='auth'){
            props.onClose();
        }
     }, [props.loginData]);

    const handleChangeMode=(e, {checked})=>{
        if (checked) props.getOptions();
         else props.disable()
    }

    if(!(isSupportBrowser && isSupportPlatform)) return null;
   
    if(props.authMode) return <></>
    
    return <Form.Checkbox toggle label='Биометрическая авторизация' checked={props.enabled} onChange={handleChangeMode}/>
})