import React from "react";
import { connect } from 'react-redux';
import { dispatchTemplateAddListValue, dispatchTemplateRemoveListValue } from '../../store/actions';

import { Button, Segment, Icon, Grid } from 'semantic-ui-react'


const _arrayBufferToBase64=( buffer )=> {
    let binary = '';
    let bytes = new Uint8Array( buffer );
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return btoa( binary );
}

const ReadFile=(file, callback)=>{
    const reader = new FileReader();
    reader.onload = function () {
        let buffer = new Uint8Array(reader.result);
        callback(true, _arrayBufferToBase64(buffer));
    };
    reader.onerror = function() {
        callback(false, reader.error);
    };
    reader.readAsArrayBuffer(file);
}

const mapStateToProps = (store, props) => { 
    const page=store.templates.edit.pages[props.page];
    const values = store.templates.edit.values || {};
    return {
        loading: store.loading,
        isMobile: store.media==='mobile' || store.media==='tablet',
        ...page.fields[props.name],
        files: (values[props.name]||[]).map((e, i)=>{ return {name: e.name, index: i} }),
        options: page.fields[props.name].options || {},
        accept: page.fields[props.name].accept||['*.*'],
        multiple: page.fields[props.name].multiple || false,
    }
}


const mapDispatchToProps = (dispatch, props) => {
  return {
     add: (data)=>dispatch(dispatchTemplateAddListValue(props.name, data)),
     remove: (index)=>dispatch(dispatchTemplateRemoveListValue(props.name, index)),     
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocFile=reduxConnect(props=> { 
    const inputRef = React.useRef(null);
    const [wait, setWaiting] = React.useState(false);
    
    const handleFileChange = (elem) => {
        if (!elem.target.files) {
            return;
        }
        if (!props.multiple && props.files.length){
            props.remove(0);
        }

        Array.from(elem.target.files).forEach(file=>{
            setWaiting(true)
            ReadFile(file, (result, data)=>{
                setWaiting(false);
                if(result) props.add({name: file.name, size: data.byteLength, buffer: data})
            })
        })
        //setFile();
    };
    
    const files=props.files.map((e, i)=>{
        return <Grid.Column key={i+e.name}>
            <Segment color='blue' >
                {e.name}
                <Button basic icon size='mini'  onClick={()=>props.remove(i)}
                circular style={{position: 'absolute', top: '0.2em',  right: 0, boxShadow: 'none'}}>
                    <Icon name='cancel'/>
                </Button>
            </Segment>
        </Grid.Column>
    })
    
    return <Segment style={{minHeight: '5rem'}} {...props.options}>
    
    <Button basic icon onClick={()=>inputRef.current.click()} disabled={wait}>
         <Icon name='attach' color='black'/>
         {props.label}
         <input type="file" ref={inputRef} onChange={handleFileChange} multiple={props.multiple} style={{ display: 'none' }} accept={props.accept.join(',')}/>
    </Button>
    <Grid columns={4} stackable>
    <Grid.Row>{files}
    </Grid.Row>
    </Grid>
    </Segment>

})