import {YandexClientID, GoogleClientID, VKClientID} from  './const'
import {appDomain, isDemo} from  './const'

const getRedirectURL=()=>{
    const hostname = window.location.hostname;
    const curentDomain = window.location.hostname.split('.').slice(-2).join('.');
    if (appDomain.indexOf(curentDomain)!==-1){
        return 'https://'+curentDomain+'/auth'
    }
    return window.location.origin
}

const getState=(provider, path)=>{
    const port = (!window.location.port) ? '' : ':'+window.location.port;
    return provider+'@'+(isDemo() ? path.replace('/','') : window.location.hostname+port);
}

export const getYandexURLAuth=(path)=>{
    if (!path) return '#'
    const YandexURL = 'https://oauth.yandex.ru/authorize';
    const redirectURL = getRedirectURL();
    const state = getState('yandex', path);
    return `${YandexURL}?response_type=token&client_id=${YandexClientID}&redirect_uri=${redirectURL}&force_confirm=yes&state=${state}`
}

export const getGoogleURLAuth=(path)=>{
    if (!path) return '#'
    const GoogleURL = 'https://accounts.google.com/o/oauth2/auth';
    const redirectURL = getRedirectURL();
    const state = getState('google', path);
    const scope = 'https://www.googleapis.com/auth/userinfo.email'
    return `${GoogleURL}?response_type=token&client_id=${GoogleClientID}&redirect_uri=${redirectURL}&prompt=select_account&state=${state}&scope=${scope}`
}

export const getVKURLAuth=(path)=>{
    if (!path) return '#'
    const vkURL = 'http://oauth.vk.com/authorize';
    const redirectURL = getRedirectURL();
    const state = getState('vk', path);    
    return `${vkURL}?response_type=code&client_id=${VKClientID}&redirect_uri=${redirectURL}&state=${state}`
}