import React from "react";
import { connect } from 'react-redux';

import { Form } from 'semantic-ui-react'

import { dispatchSetPush, dispatchSetPushServer, dispatchLoading } from '../store/actions';

function urlB64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}


const getRegistrationServiceWorker=(callback)=>{
  if (!('serviceWorker' in navigator)) {
      callback(null);
      return;
  }
  navigator.serviceWorker.getRegistrations()
      .then((registrations) => {
        if (registrations.length===0) callback(null)
            else callback(registrations[0]);
      })
      .catch((error) => {
        callback(null);
      })
}

const checkSubscribe=(registration, callback)=>{
    if(!registration) {
        callback(false);
        return;
    }
    registration.pushManager.getSubscription()
		.then(subscription=>callback(!!subscription))
		.catch(error=>callback(false))
}


const subscribeUser=(registration, publicKey, callback)=>{
    const applicationServerKey = urlB64ToUint8Array(publicKey);
	registration.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: applicationServerKey
		})
		.then(subscription=>{
            const token = JSON.parse(JSON.stringify(subscription));
            callback(token)
		})
		.catch(err=> {
            callback(null)
			console.log('Failed to subscribe the user: ', err);
		});
}

const unSubscribeUser=(registration, callback)=>{
    registration.pushManager.getSubscription()
		.then(subscription=> {
			if (subscription) {
				return subscription.unsubscribe();
			}
		})
		.catch(error=>{
			console.log('Error unsubscribing', error);
		})
		.then(()=> {
			callback()
		});
    
}



const mapStateToProps = (store) => { 
    return {  
        size: store.media=='desktop' ? null : 'big',
        checked: store.user.push.enable,
        publicKey: store.user.data.push_public_key,
        resultSave: store.user.push.resultSave||null,
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
       setLoading: (loading)=>dispatch(dispatchLoading(loading)),
       setPush: (enable)=>dispatch(dispatchSetPush({enable: enable})),
       clearResult: ()=>dispatch(dispatchSetPush({resultSave: null})),
       saveOnServer: (info, unsubscribe)=>dispatch(dispatchSetPushServer(info, unsubscribe)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const PushCheckbox=reduxConnect(props=> { 
    const [disabled, setDisabled] = React.useState(true);
    const [registration, setRegistration] = React.useState(null)

    React.useEffect(()=> {
        getRegistrationServiceWorker(reg=>{
            //console.log(reg)            
            setDisabled(!reg)
            setRegistration(reg);
            checkSubscribe(reg, result=>{
                props.setPush(result)
            })
        })      

        
     }, []);
     
     React.useEffect(()=> {
         if(!props.resultSave) return;
         props.setPush(!props.resultSave.unsubscribe);
         props.clearResult()
     }, [props.resultSave]);
     
    const handleClick=(e, {checked})=>{
        props.setLoading(true)
        if(checked) 
            subscribeUser(registration, props.publicKey, token=>{
                props.setLoading(false);
                props.saveOnServer(token, false);
            })
        else 
            unSubscribeUser(registration, ()=>{
                props.setLoading(false);
                props.saveOnServer(null, true)
            })
    }
    return <Form.Checkbox size={props.size} toggle label='Push-уведомления' checked={props.checked} onClick={handleClick} disabled={disabled}/>
})