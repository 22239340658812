import React from "react";
import { connect } from 'react-redux';
import { Dropdown, Icon, Label,Modal,Button,List } from 'semantic-ui-react'
import { formatDateTime } from '../funcDate'

import { 
    dispatchDownloadDraft,
    dispatchClearDownloadDraft,
    dispatchEditLoadingTemplate,
} from  '../../store/actions';

const JSZip = require("jszip");

const extractDraftData=(buffer, callback)=>{
    
    JSZip.loadAsync(buffer)
        .then(files=> {
            files.file('data.json').async("string")
                .then(data=>{
                    callback(JSON.parse(data))
                })
        });
   
   
}

const mapStateToProps = (store, props) => { 
    return {
        loading: store.loading,
        isMobile: store.media==='mobile' || store.media==='tablet',
        list: store.templates.draft,
        templates: store.templates.list.map(e=>{return {guid: e.form.guid, icon: e.form.icon, name: e.form.name} }),
        count: store.templates.draft.length,
        downloadBuffer: store.templates.download,
        
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      download: (url)=>dispatch(dispatchDownloadDraft(url)),
      clear: ()=>dispatch(dispatchClearDownloadDraft()),
      openDocument: (data)=>dispatch(dispatchEditLoadingTemplate(data)),
  };
};

const MenuItem=(props)=>{
   return  <>
        <Dropdown.Divider />
        <Dropdown.Item onClick={()=>props.show()}>
             <Icon color='brown' name='file text'/>                         
             Черновики
             <Label circular size='tiny' color='grey' content={props.count} style={{float: 'right'}}/>
        </Dropdown.Item>
    </> 
}


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocDrafts=reduxConnect(props=> {
    const [dialog, showDialog] = React.useState(false)
    
    //console.log(props)
    React.useEffect(()=> {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if(props.downloadBuffer) 
            extractDraftData(props.downloadBuffer, (data)=>{
                props.openDocument({guid: data.template.guid, values: data.values, guidDoc: data.guid, dateSave: data.dateSave});
                props.clear();
                showDialog(false);
            })
     }, [props.downloadBuffer]);
    
    if (props.count===0) return null;

    
    const list=props.list.map(e=>{
        //console.log(e)
        const template = props.templates.find(t=>t.guid===e.template);
        const icon = !template ? {name: ''} : {...template.icon};
        const name = !template ? '<Неизвестный тип>' : template.name;
        return  <List.Item key={e.guid} onClick={()=>props.download(e.url)}>
        <List.Icon {...icon} size='large' verticalAlign='middle' />
            <List.Content>
                <List.Header as='a'>{name}</List.Header>
                <List.Description as='a'>Изменен: <span className='ui teal'>{formatDateTime(e.modified)}</span></List.Description>
            </List.Content>
        </List.Item>
    })
    
    const wind=<Modal open={dialog} onClose={()=>showDialog(false)}>
        <Modal.Header>
            Черновики документов
            <Label circular size='tiny' color='grey' content={props.count} style={{marginLeft: '1em'}}/>
        </Modal.Header>
        <Modal.Content scrolling>
            <List divided relaxed selection>
            {list}
            </List>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={()=>showDialog(false)}>
              <Icon name='close' />
              Закрыть
            </Button>
        </Modal.Actions>
    </Modal>
    
    return <>
        <MenuItem count={props.count} show={()=>showDialog(true)}/>
        {dialog && wind}
    </>
    
})