import React from "react";
import { connect } from 'react-redux';
import { dispatchTemplateAddListValue, dispatchTemplateSetListValue } from '../../store/actions';
import { Portal, Segment, Header, Button, Dimmer, Form, Checkbox, Select, Input, TextArea } from 'semantic-ui-react'
import { formatDateMySQL, formatDate } from '../funcDate'

const mapStateToProps = (store, props) => {
    const page=store.templates.edit.pages[props.page];
    const values = store.templates.edit.values || {};
    return {
        loading: store.loading,
        media: store.media,
        data: (props.row===-1) ? {} : (values[props.name] && values[props.name][props.row] || {}),
        cols: page.fields[props.name].cols,
        header: page.fields[props.name].label || '',
        groups: page.groups || {},
        lists: store.templates.edit.lists || {},
    }
}


const mapDispatchToProps = (dispatch, props) => {
  return {
      add: (data)=>dispatch(dispatchTemplateAddListValue(props.name, data)),
      set: (row, data)=>dispatch(dispatchTemplateSetListValue(props.name, row, data)),
  };
};

const controls={
    date: {type: 'date'},
    number: {type: 'number', step: 'any'},
    textarea: {control: TextArea},
    boolean: {control: Checkbox, toggle: true},
    select: {control: Select},
}

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);
export const DocEditRow=reduxConnect(props=> {
    const [data, setData] = React.useState({})    
    const [loading, setLoading] = React.useState(true)
    React.useEffect(()=> {
        if(props.row===null) return;
        const newData={...props.data}
        if(props.row===-1){            
            Object.keys(props.cols).forEach(key=>{
                const col=props.cols[key];
                if (col.type==='date' && col.default==='now' && !newData[key]){ 
                     newData[key]= formatDateMySQL(new Date()).slice(0,10);
                }else{
                     newData[key]=col.default || ( (col.type==='number') ? 0 : '')
                }                
            })
        }
        setData(newData);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.row]);
    
    const isOpen=(props.row!==null)
    const handleChange=(e, {name, value, checked})=>{
        let val = checked===undefined ? value :checked;
        if (props.cols[name].type==='number') val = Number(val);
        const newData = {...data, [name]: val||checked}
        
        const funcText = props.cols[name].change
        if (funcText){            
            try {
                const func = new Function('data', funcText)
                func(newData)
            } catch (err) {
                alert(err)
            }
        }
        setData(newData)
    }
    
    const saveRow=()=>{
        if(props.row===-1) props.add({...data})             
            else props.set(props.row, {...data});
        setLoading(true);
        props.onClose();
    }
    
    const groups = []
    
    const all_fields=Object.keys(props.cols).map(key=>{
        const col=props.cols[key];
        const group = col.group || '#not_group_'+key
        if (groups.indexOf(group)===-1) groups.push(group) 

        if (col.type==='row_number') return null        
        const options=col.options || {}        
        const fieldProps={control: Input, ...options, width: col.width || 16}
        if (col.type==='select'){             
            const items = typeof(col.items)==='object' ? col.items : props.lists[col.items]
            fieldProps.options=items.map(e=>{
                    const itemKey=Object.keys(e)[0];
                    const data=typeof(e[itemKey])==='object' ? e[itemKey] : {};
                    return {key: itemKey, value: itemKey, text: e[itemKey], ...data};
            })
        }
        if (col.type==='boolean') fieldProps.checked =data[key]||false
            else fieldProps.value = data[key]||((col.type=='number') ? 0 : '')
             
        
        Object.assign(fieldProps, controls[col.type] || {})
        
        return { 
            group: group,
            render: <Form.Field key={key+props.row} name={key} label={col.label} 
                required={col.required || false} 
                onChange={handleChange} {...fieldProps} /> 
       }
    })


    const form_groups = groups.map(group=>{
        const fields = all_fields.filter(e=>e && e.group===group).map(e=>e.render)    
        if (group.indexOf('#not_group_')!==-1) return fields[0];
        const options = props.groups[group] || {}
        return <Form.Group key={props.page+'/'+group} widths={options.width||16}>{fields}</Form.Group>
    })
    
    
    const segmentStyle={
                left: '30%',
                position: 'fixed',
                top: '20%',
                width: '40%',
                zIndex: 1500,
              }
    if (props.media==='mobile') Object.assign(segmentStyle, {left:0, top:0, width: '100%'})
    else if (props.media==='tablet') Object.assign(segmentStyle, {left:'5%', top:'5%', width: '90%'})
    
    return  <div style={{position: 'fixed',  opacity: 0.3,
                        top: '0',left: '0',bottom: '0',right: '0',
                        zIndex: '10', display: isOpen ? 'block' : 'none'}}>
            <Dimmer active={isOpen}/>
            <Portal onClose={props.onClose} open={isOpen} closeOnPortalMouseLeave={false} closeOnDocumentClick={false}>
            <Segment disabled={props.loading} loading={loading}
              style={segmentStyle}
            >
             { props.header && <Header>{props.header}</Header>}
              <Form>
                {form_groups}
              </Form>
              <Button style={{marginTop: '1rem'}}
                content='Отмена'                
                onClick={props.onClose}
              />
              <Button
                content={ props.row===-1 ? 'Добавить' : 'Изменить' }
                color='blue'
                onClick={saveRow}
              />
            </Segment>
          </Portal></div>

})