import React from "react";
import { connect } from 'react-redux';
import { dispatchEditTemplate, dispatchDraftGetURLs, dispatchTemplateWaitList, dispatchDeleteDraft, dispatchLoadDraftList } from '../../store/actions';
import { DocPages } from './DocPages';
import { DocSave } from './DocSave';
import { formatDateTime } from '../funcDate'
import { PrintDraft } from './PrintDraft'





import { Button, Message, Form, Segment, Portal, Icon, Dimmer, Header } from 'semantic-ui-react'


const mapStateToProps = (store, props) => { 
    return {
        loading: store.loading,
        isMobile: store.media==='mobile' || store.media==='tablet',
        icon: store.templates.edit.icon.name,
        enablePrint: store.templates.edit.document.print!==false,
        iconColor: store.templates.edit.icon.color,
        title: store.templates.edit.name,
        isNew: !store.templates.edit.dateSave,
        changed: true, //!!store.templates.edit.change,
        dateSave: store.templates.edit.dateSave,
        guidDoc: store.templates.edit.guidDoc,
        template: store.templates.edit.guid,
        saveAsDocument: (store.templates.edit.resultSave || {}).saveAsDocument || false,
        urlDownload: store.templates.edit.urls && store.templates.edit.urls.document_download,
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      closeForm: ()=>dispatch(dispatchEditTemplate(null)),
      updateURL: (guid, template)=>dispatch(dispatchDraftGetURLs(guid, template)),
      addWait: (data)=>dispatch(dispatchTemplateWaitList([data], null)),
      deleteDraft: (guid, template)=>dispatch(dispatchDeleteDraft(guid, template)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocForm=reduxConnect(props=> {
    const [saveDoc, showSaveDoc] = React.useState(null);
    const [runPrintDraft, printDraft] = React.useState(false);
    
    React.useEffect(()=> {
        props.updateURL(props.guidDoc, props.template);
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, []);
     
    React.useEffect(()=> {
        if(props.saveAsDocument) {
            props.addWait({guid: props.guidDoc, url: props.urlDownload});
            if(!props.isNew) props.deleteDraft(props.guidDoc, props.template);
            props.closeForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, [props.saveAsDocument]);
     
    const handleDelete=()=>{
        props.deleteDraft(props.guidDoc, props.template);
    }

    const padding= props.isMobile ? 0 : '1em';
    const style = { zIndex: 1050, position: 'fixed', display: 'flex', flexFlow: 'column',
                    top: '0', bottom: padding, left: padding, right: padding, marginBottom: 0}
    if (props.isMobile){
        style.padding =0;
        style.marginTop=0;
        style.borderRadius=0;
    }
    
    return  <>
        <Dimmer active />
        <Portal
        onClose={() => props.closeForm()}
        open={true} closeOnPortalMouseLeave={false} closeOnDocumentClick={false} closeOnEscape={false}> 
              
        <Segment  style={style} loading={props.loading}>             
            <div style={{position: 'absolute', zIndex: '10', right: props.isMobile ? '.2rem' : '1.2rem',top: props.isMobile ? '.2rem' : '1.3rem'}}>
                <Button size='mini' basic icon circular onClick={()=>props.closeForm()}>
                    <Icon color='red' name='close'/>
                </Button>
            </div>
            <Header as='h4' style={{marginTop: '0.2em', marginBottom: '0.2em', paddingBottom: '1em', borderBottom: 'solid 1px #cdcdcd'}}>
              <Icon name={props.icon} color={props.iconColor}/>
              <Header.Content>{props.title} {props.isNew ? ' (новый)' : ' ('+formatDateTime(props.dateSave)+')' }</Header.Content>
            </Header>
            <div>
                
                
                <Button icon labelPosition='left' color='grey' disabled={!props.changed} onClick={()=>showSaveDoc('draft')}>
                  <Icon name='file outline' />
                  Черновик
                </Button>
                <Button icon labelPosition='right' color='blue' disabled={!props.changed} onClick={()=>showSaveDoc('document')}>
                  Опубликовать
                  <Icon name='send' />
                </Button>
                
                {!props.isMobile && props.enablePrint && <Button icon  basic onClick={()=>printDraft(true)} style={{marginLeft: '10%'}}>                  
                  <Icon name='print' color='black'/>
                </Button>}
                
                <Button icon  basic disabled={props.isNew} onClick={handleDelete} style={{position: 'absolute', right: '0'}}>                  
                  <Icon name='trash' color='red'/>
                </Button>
              </div>
            <DocPages />
        </Segment>
    </Portal>
    {saveDoc!==null && <DocSave type={saveDoc} onSuccess={()=>showSaveDoc(null)} onError={()=>showSaveDoc(null)}/>}  
    {runPrintDraft && <PrintDraft onClose={()=>printDraft(false)}/>}
    </>

})