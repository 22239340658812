import { 
    actionSetWebauthnProps
} from  './actions'


const keyWebauthn = 'webauthn';

const initialState = {
    enabled:!!localStorage.getItem(keyWebauthn),
    error: null,
    guid: localStorage.getItem(keyWebauthn)
};


export const reducerWebauthn=(state = initialState, action) => {
    switch (action.type) {
        case actionSetWebauthnProps:           
           if (action.data.guid) {
               localStorage.setItem(keyWebauthn, action.data.guid)
           }
           if (action.data.enabled===false) {
               localStorage.removeItem(keyWebauthn)
           }
           if (action.data.token || action.data.message) {
               return {...state, login: action.data, authShow: false}; 
           }
           return {...state, ...action.data};      
                
        default:
          return {...state};
    }
}