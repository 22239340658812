import React from 'react'
import { connect } from 'react-redux';

import { InvisibleSmartCaptcha  } from '@yandex/smart-captcha'; //Переименовал @yandex\smart-captcha\module.mjs
import { CaptchClientKey } from '../store/const';
import { getYandexURLAuth, getGoogleURLAuth, getVKURLAuth } from '../store/extAuth';

import versionData from '../version.json'

import fingerprint from '../images/fingerprint.svg'

import { Button, Form, Grid, Header, Image, Message, Segment, Icon } from 'semantic-ui-react'

import { dispatchUserLogin, 
dispatchUserCode, 
dispatchResetPassword, 
dispatchSetUserProps,
dispatchOpenIDLogin,
dispatchPinCodeMode, 
} from '../store/actions';

import { Webauthn } from './Webauthn'
import { MobileID } from './MobileID'
import { PinCode } from './PinCode'
import {  AgreementDocument } from './AgreementDocument';



const imgWrapStyle = {
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center'
}


const mapStateToProps = (store) => {    
    const openID = store.user.openID || {}
    return {
        versionFrontend: versionData.version || '0.0.0',
        versionBackend: store.version.backend,
        versionCreateDocs: store.version.createdocs,
        disabled: false,
        media: store.media,
        error: store.user.error,
        errorCode: store.user.errorCode,
        loading: store.loading,
        sendCode: store.user.sendCode,
        pathApp: store.user.path,
        openIDProvider: openID.name || '',
        openIDToken: openID.token || '',
        openIDTokenType: openID.token_type || '',
        openIDUserID: openID.user_id || '',
        enabledWebauthn: store.webauthn.enabled,
        webauthnVisible: store.webauthn.authShow || false,
        pinSet: store.user.pinCodeMode.mode==='auth' && store.user.pinSet,
    }
    
}


const mapDispatchToProps = (dispatch) => {
  return {
      onLogin: (data)=> dispatch(dispatchUserLogin(data.login, data.password, data.captcha)), 
      onOpenIDLogin: (provider, code, options)=> dispatch(dispatchOpenIDLogin(provider, code, options)),
      onCode: (data)=> dispatch(dispatchUserCode(data.login, data.code, true)), 
      resetPassword: (data)=> dispatch(dispatchResetPassword(data.login, data.captcha)),
      setProps: (data)=> dispatch(dispatchSetUserProps(data)),      
      setPinModeAuth: ()=>dispatch(dispatchPinCodeMode({mode:'auth'})),
      clearPinModeAuth: ()=>dispatch(dispatchPinCodeMode({mode: null})),      
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const LoginForm=reduxConnect(props=> {
  const [data, setData] = React.useState({login:"", password:"", code: "", captcha: ''})
  const [error, setError] = React.useState('')
  const [resetPasswordMode, setResetPasswordMode] = React.useState(false)
  const [captcha, setCaptcha] = React.useState({visible: false, mode: ''})
  const [isWebauthn, runWebauthn] = React.useState(props.enabledWebauthn)
  
  const isMobile = props.media==='mobile' || props.media==='tablet';
  
  const {disabled} = props

  React.useEffect(()=> {
        props.setPinModeAuth()
  }, [])

  React.useEffect(()=> {
      if (props.openIDToken){
            let options= (props.openIDUserID) ? {user_id: props.openIDUserID} : null;
            props.onOpenIDLogin(props.openIDProvider, props.openIDToken, options);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openIDToken]);

  
  React.useEffect(()=> {      
    setError(props.error)
  }, [props.error, props.errorCode]); 
  
  const handleChange=(e, {name, value})=>{
	setData({ ...data, [name]: value })
  }
  
  const successCaptcha=(value)=>{
    if (captcha.mode==='login'){
        props.onLogin({...data, captcha: value }); 
    }else if (captcha.mode==='reset'){
        props.resetPassword({...data, captcha: value });
    }
    setCaptcha({visible: false, mode: ''})
    //setData({ ...data, captcha: value })
  }
  
  const handleLogin=()=>{
    if(!data.login){
        setError("Введите логин и пароль");
        return
    }
    //if (data.captcha) props.onLogin(data)   
    //    else 
    setCaptcha({visible: true, mode: 'login'});
    
  }
  const handleReset=()=>{
      //if (data.captcha) props.resetPassword(data)   
      //    else 
      setCaptcha({visible: true, mode: 'reset'});            
  }
  
  
  const handleCode=()=>{
    if(!data.code){
        setError("Проверьте почту и введите код");
        return
    }
	props.onCode(data)
  } 
  
  const resetPassword=(e)=>{
      e.preventDefault();      
      setResetPasswordMode(true)
  }
  
  const handleBack=()=>{
       setResetPasswordMode(false);
       props.setProps({sendCode:false})
       setCaptcha({visible: false, mode: ''})
  }
  
  
  const loginMode = (!props.sendCode && !resetPasswordMode);
  const sendCodeMode = (props.sendCode);
  const resetMode = (!props.sendCode && resetPasswordMode );
  
  const loginStyle = loginMode ? {} : {display: 'none'};
  const sendCodeStyle = sendCodeMode ? {} : {display: 'none'};
  const resetStyle = resetMode ? {} : {display: 'none'};
  
  const btnLoginType = loginMode ? 'submit' : 'button';
  const btnGetCodeType = resetMode ? 'submit' : 'button';
  const btnSendCodeType = sendCodeMode ? 'submit' : 'button';
  
  const btnBack = <Button type='reset' icon='arrow left' onClick={handleBack}/>
  
  const webauthnOrPin = isWebauthn || (props.pinSet && isMobile);
  
  const winStyle= isMobile ? {position: 'absolute',  padding: 0, top: '5%'} : {};
  
  if (webauthnOrPin) winStyle.display = 'none';
  
  if (webauthnOrPin){      
      return <Segment basic loading={props.loading} className='fullscreen' 
                style={{backgroundColor: 'grey', display: 'flex', flexDirection: 'column'}}>
        <div style={imgWrapStyle}>
            <Image src='/src/logo.svg' style={{opacity: '0.7', height: '10em'}} />
        </div>
       {isWebauthn && <Webauthn mode='auth' onClose={()=>runWebauthn(false)}/>}
       {props.pinSet && !props.webauthnVisible && <PinCode/>}
      </Segment>
  }
  
  return (<React.Fragment>
  <Grid textAlign='center' style={{ height: '100vh', backgroundColor: ( webauthnOrPin ? 'grey': null)}} verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 450, ...winStyle }} >
	  <Segment raised loading={props.loading || captcha.visible} basic={props.media==='mobile'}>
		  <Header as='h2' color='blue' textAlign='center' disabled={disabled}>
            <Image src='/src/logo.svg' style={{opacity: '0.6'}} /> 
            Подписание документов
		  </Header>
		  <Form size='large' >
			
            <Segment style={loginStyle}  basic={props.media==='mobile'}>
			  <Form.Input fluid icon='user' iconPosition='left' 
                        placeholder='Логин или адрес электронной почты' 
						disabled={disabled}
						onChange={handleChange}
						name='login'
						value={data.login}/>
               <div style={{textAlign:'right'}}><a href='/' onClick={resetPassword} >Забыли пароль?</a></div>
			  <Form.Input 
				fluid
				icon='lock'
				iconPosition='left'
				placeholder='Пароль'
				type='password'
				disabled={disabled}				
				onChange={handleChange}
				name='password'
				value={data.password}
                autoComplete="on"
			  />
             
			  <Button type={btnLoginType} color='blue' fluid size='large' disabled={disabled || !data.login} onClick={handleLogin}>
				Войти
			  </Button>
              <Segment basic style={{padding: 0}}>                  
                  <Button.Group fluid>
                    {isMobile && <MobileID msisdn={data.login}/>}
                    <Button basic icon color='grey' as='a' href={getYandexURLAuth(props.pathApp)}>
                        <Icon name='yandex' color='red'/>
                        Яндекс
                    </Button>
                    <Button basic icon color='grey' as='a' href={getGoogleURLAuth(props.pathApp)}>
                        <Icon name='google' color='blue'/>
                        Google
                    </Button>
                    {/*<Button basic icon color='grey' as='a' href={getVKURLAuth(props.pathApp)}>
                        <Icon name='vk' color='blue'/>
                        ВКонтакте
                    </Button>*/}
                  </Button.Group>
              </Segment>
              
              { props.enabledWebauthn && <Button basic onClick={()=>runWebauthn(true)}>
                <Image src={fingerprint} size='small'/>                
              </Button> }
              
			</Segment>
            
            
            <Segment style={resetStyle}>
                <Message info>
                Введите логин или адрес электронной почты.
                На указанный в вашем профиле адрес электронной почты будет отправлен код подтверждения.
                </Message>
                 <Form.Input fluid icon='user' iconPosition='left' autoComplete="one-time-code"
                        placeholder='Логин или адрес электронной почты' 
						disabled={disabled}
						onChange={handleChange}
						name='login'
						value={data.login}/>
                <Button.Group fluid>
                    {btnBack}
                    <Button type={btnGetCodeType} color='black' size='large' onClick={handleReset} disabled={!data.login}>
                       Получить код
                    </Button>
                </Button.Group>
            </Segment>
            
            <Segment style={sendCodeStyle}>
                <Message info>
                Пожалуйства, введите код подтверждения, отправленный на Ваш адрес электронной почты.
                </Message>
                 <Form.Input fluid icon='question circle' iconPosition='left'  autoComplete="one-time-code"
                        placeholder='Код подтверждения' 
						disabled={disabled}
						onChange={handleChange}
						name='code'
						value={data.code}/>
                <Button.Group fluid>
                    {btnBack}
                    <Button type={btnSendCodeType} color='green' size='large' onClick={handleCode} disabled={!data.code}>
                       Продолжить
                    </Button>
                </Button.Group>
            </Segment>
            
		  </Form>
          <AgreementDocument url='/src/policy.txt' text='Политика обработки персональных данных'/>.
		  {error && <Message error>{error}</Message>}
	  </Segment>      
    </Grid.Column>    
    
  </Grid>
  {captcha.visible && <InvisibleSmartCaptcha
        sitekey={CaptchClientKey}
        onSuccess={successCaptcha}
        visible={captcha.visible}
        onChallengeHidden={()=>setCaptcha({visible: false, mode: ''})}
  />}
    <div style={{position: 'absolute',right: '1em',fontSize: '8pt', color: 'grey'}}>
        Версии:&nbsp;
        <span style={{color: 'green'}}>
            {props.versionFrontend}           
        </span>
        &nbsp;/&nbsp; 
        <span style={{color: 'blue'}}>
            {props.versionBackend}           
        </span>
        &nbsp;/&nbsp; 
        <span style={{color: 'violet'}}>
            {props.versionCreateDocs}           
        </span>        
    </div>
  </React.Fragment>)
})

