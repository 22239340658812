
export const actionRequest = 'api-request'
export const dispatchRequest = (action, data, cbAction)=>{
    return {type: actionRequest, action: action, data: data, callbackAction: cbAction} 
};

export const actionLoading = 'set-loading';
export const dispatchLoading = (loading)=>{return {type: actionLoading, data: loading}};

export const actionMediaType = 'set-media';
export const dispatchMediaType=(media)=> {return {type: actionMediaType, media: media} }


export const actionDownload = 'download'
export const dispatchDownload = (url, cbAction)=>{
    return {type: actionDownload, url: url, callbackAction: cbAction} 
};

export const actionUpload = 'upload'
export const dispatchUpload = (url, data, meta, cbAction)=>{
    return {type: actionUpload, url: url, data: data, meta: meta, callbackAction: cbAction} 
};

export const actionSimpleRequest = 'simple-request';
export const dispatchSimpleRequest = (action, data)=>{
    return dispatchRequest(action, data, actionSimpleRequest) 
};

export const actionResetSimpleRequest = 'reset-simple-request';
export const dispatchResetSimpleRequest = ()=>{return {type: actionResetSimpleRequest} };


export const actionVersionBackend = 'version-backend';
export const dispatchVersionBackend = ()=>{
    return dispatchRequest('version', {hideLoading:true}, actionVersionBackend) 
};

export const actionVersionCreateDocs = 'version-createdocs';
export const dispatchVersionCreateDocs = ()=>{
    return dispatchRequest('ext/version', {hideLoading:true}, actionVersionCreateDocs) 
};

//------------------------------------------------------------------
//------------------------------------------------------------------
//------------------------------------------------------------------
const userPrefix = 'user';

export const actionSetLocation = userPrefix+'/setLocation';
export const dispatchSetLocation = (path)=>{return {type: actionSetLocation, path: path} };

export const actionGetToken = userPrefix+'/getToken';
export const dispatchGetToken = ()=>{return {type: actionGetToken}};

export const actionSetToken = userPrefix+'/setToken';
export const dispatchSetToken = (token)=>{return {type: actionSetToken, token: token} };

export const actionLogout = userPrefix+'/logout';
export const dispatchLogout = ()=>{return {type: actionLogout}};

export const actionSetUserProps = userPrefix+'/setProps';
export const dispatchSetUserProps = (data)=>{return {type: actionSetUserProps, data: data} };

export const actionPinCodeMode = userPrefix+'/setPinCodeMode';
export const dispatchPinCodeMode = (data)=>{return {type: actionPinCodeMode, data: data} };

export const actionUserLogin = userPrefix+'/login';
export const dispatchUserLogin = (login, password, captcha)=>{
    const data = {method: 'password', login: login, password: password, captcha: captcha};
    return dispatchRequest('auth', data, actionUserLogin) 
};

export const dispatchUserCode = (login, code, setPassword=null)=>{
    const data = {login: login, code: code, set_password: setPassword};
    return dispatchRequest('code', data, actionUserLogin) 
};

export const dispatchResetPassword = (login, captcha)=>{
    const data = {login: login, captcha: captcha};
    return dispatchRequest('reset', data, actionUserLogin) 
};

export const actionOpenIDLogin = userPrefix+'/loginOpenID';
export const dispatchOpenIDLogin = (provider, code, options)=>{
    const data = {method: provider, code: code};
    if (options) data.options = options;
    return dispatchRequest('auth', data, actionUserLogin) 
};

export const dispatchTokenLogin = (token)=>{
    const data = {method: 'token', token: token};
    return dispatchRequest('auth', data, actionUserLogin) 
};

// MobileID
export const actionAfterSendMobileID = userPrefix+'/setAfterMobileID';
export const dispatchSendMobileID = (msisdn)=>{
    const data = {method: 'mobile_id', msisdn: msisdn};
    return dispatchRequest('auth', data, actionAfterSendMobileID) 
};

export const actionSetAuthToken = userPrefix+'/setAuthToken';
export const dispatchSetAuthToken = (token)=>{
    return {type: actionSetAuthToken, data: token} 
};

export const dispatchMobileIDTokenLogin = (token)=>{
    const data = {method: 'mobile_id_token', token: token};
    return dispatchRequest('auth', data, actionUserLogin) 
};

export const dispatchQRCodeTokenLogin = (token)=>{
    const data = {method: 'qr_code_token', token: token};
    return dispatchRequest('auth', data, actionUserLogin) 
};

export const actionSetPassword = userPrefix+'/setPassword';
export const actionAfterSetPassword = userPrefix+'/AfterSetPassword';
export const dispatchSetPassword = (password)=>{
    const data = {password: password};
    return dispatchRequest('set-password', data, actionAfterSetPassword) 
};

export const actionSetPrivateKey = userPrefix+'/setPrivateKey';
export const actionAfterSetPrivateKey = userPrefix+'/setAfterSetPrivateKey';
export const dispatchSetPrivateKey = (passphrase)=>{
    const data = {passphrase: passphrase};
    return dispatchRequest('create-key', data, actionAfterSetPrivateKey) 
};

export const actionSetPush = userPrefix+'/push-set';
export const dispatchSetPush = (data)=>{return {type: actionSetPush, data: data} };

export const actionSetPushServerResult = userPrefix+'/push-set-server';
export const dispatchSetPushServer = (info, unsubscribe=false)=>{
    const data = {info: info, unsubscribe: unsubscribe};
    return dispatchRequest('push', data, actionSetPushServerResult) 
};

export const actionAfterChangeLogin = userPrefix+'/setAfterChangeLogin';
export const dispatchChangeLogin = (login)=>{
    const data = {login: login};
    return dispatchRequest('set-login', data, actionAfterChangeLogin) 
};

//***************** Webauthn ****************
export const actionSetWebauthnProps = userPrefix+'/webauthn-set';
export const dispatchSetWebauthnProps = (data)=>{return {type: actionSetWebauthnProps, data: data} };

export const actionWebauthnCreate = userPrefix+'/webauthn-create';
export const actionWebauthnRegOptions = userPrefix+'/webauthn-options';
export const dispatchWebauthnRegOptions = ()=>{
    const data = {action: 'options'};
    return dispatchRequest('webauthn-reg', data, actionWebauthnCreate) 
};

export const actionSaveWebauthn = userPrefix+'/webauthn-save';
export const dispatchWebauthnCreate = (regData)=>{
    const data = {action: 'create', data: regData};
    return dispatchRequest('webauthn-reg', data, actionSaveWebauthn) 
};

export const actionWebauthnAuth = userPrefix+'/webauthn-auth';
export const dispatchWebauthnAuth = (options)=>{
    const data = {method: 'webauthn', ...options};
    return dispatchRequest('auth', data, actionSetWebauthnProps) 
};
export const dispatchSetWebauthnUser = (data)=>{return {type: actionUserLogin, data: data} };


//************** PIN *****************
export const dispatchSendPinKey = (publicKey)=>{
    const data = {public_key: publicKey};
    return dispatchRequest('set-pin-key', data, actionPinCodeMode) 
};

export const dispatchGetPinToken = (user)=>{
    const data = {method: 'pin', user: user};
    return dispatchRequest('auth', data, actionPinCodeMode) 
};

//******************************************************************


//------------------------------------------------------------------
//------------------------------------------------------------------
//------------------------------------------------------------------
const docPrefix = 'doc';
export const actionLoadListDocs = docPrefix+'/setLoad';
export const dispatchLoadListDocs = (status=null)=>{
    const data = {status: status};
    return dispatchRequest('doc-list', data, actionLoadListDocs) 
};

export const actionResetLastLoad = docPrefix+'/resetLastLoad';
export const dispatchResetLastLoad = ()=>{return {type: actionResetLastLoad}};

export const actionLoadListStorage = docPrefix+'/loadListStorage';
export const dispatchLoadListStorage = ()=>{return {type: actionLoadListStorage}};

export const actionSetListType = docPrefix+'/listType';
export const dispatchSetListType = (listType)=>{return {type: actionSetListType, data: listType}};

export const actionSetListSort = docPrefix+'/listSort';
export const dispatchSetListSort = (name, sort)=>{return {type: actionSetListSort, data: {name: name, sort: sort}}};

export const actionSetDocument = docPrefix+'/setDocument';
export const dispatchLoadDocument = (docs, url=true)=>{
    const data = docs.map(guid=>{return {guid: guid, url: url}});
    return dispatchRequest('doc-get', data, actionSetDocument) 
};

export const actionSignDocument = docPrefix+'/signDocument';
export const dispatchSignDocument = (docs, passphrase)=>{
    const data = {docs: docs, passphrase: passphrase, public_key: false};
    return dispatchRequest('sign', data, actionSignDocument) 
};

export const dispatchRejectSignDocument = (docs, description)=>{
    const data = {docs: docs, description: description};
    return dispatchRequest('reject', data, actionSignDocument) 
};

export const dispatchCancelRejectSignDocument = (docs)=>{
    const data = {docs: docs};
    return dispatchRequest('cancel-reject', data, actionSignDocument) 
};

export const dispatchCancelSignDocument = (docs, description)=>{
    const data = {docs: docs, description: description};
    return dispatchRequest('cancel', data, actionSignDocument) 
};

export const dispatchResetCancelSignDocument = (docs, description)=>{
    const data = {docs: docs, description: description};
    return dispatchRequest('reset-cancel', data, actionSignDocument) 
};

export const dispatchReturnSignDocument = (docs, description)=>{
    const data = {docs: docs, description: description};
    return dispatchRequest('return-to-work', data, actionSignDocument) 
};

export const dispatchNotRelevantSignDocument = (docs, description)=>{
    const data = {docs: docs, description: description};
    return dispatchRequest('not-relevant', data, actionSignDocument) 
};


export const actionSetPrintStatus = docPrefix+'/printStatus';
export const dispatchSetPrintStatus = (guid, status)=>{
    const data = {guid: guid, status: status, hideLoading:true};
    return dispatchRequest('doc-print-status', data, actionSetPrintStatus) 
};


//**************
const cpPrefix = 'cryptopro';
export const actionCryptoProCheckSystem = cpPrefix+'/check-system';
export const dispatchCryptoProCheckSystem = ()=>{return {type: actionCryptoProCheckSystem}};

export const actionCryptoProSetData = cpPrefix+'/set';
export const dispatchCryptoProSetData = (data)=>{return {type: actionCryptoProSetData, data: data}};

export const actionCryptoProGetCertificates = cpPrefix+'/get-sertificates';
export const dispatchCryptoProGetCertificates = ()=>{return {type: actionCryptoProGetCertificates}};

export const actionSignCryptoProDocument = cpPrefix+'/sign';
export const dispatchSignCryptoProDocument = (docs, passphrase)=>{
    return {type: actionSignCryptoProDocument, docs: docs, passphrase: passphrase}
};

//export const actionSetSignCryptoPro = cpPrefix+'/set-sign';
export const dispatchSetSignCryptoPro = (signs)=>{
    const data = {docs: Object.keys(signs), passphrase: '', cryptopro: true, signs: signs};
    return dispatchRequest('sign', data, actionSignDocument)
};

//******************************************************************


//------------------------------------------------------------------
//------------------------------------------------------------------
//------------------------------------------------------------------
const templatePrefix = 'templates';

export const actionAddUsersCache = templatePrefix+'/users-cache';
export const dispatchAddUsersCache = (user)=>{
    return {type: actionAddUsersCache, user:user}  
};

export const actionSetTemplates = templatePrefix+'/set';
export const actionLoadTemplates = templatePrefix+'/get';
export const dispatchLoadTemplates = ()=>{
    return dispatchRequest('templates', {}, actionSetTemplates) 
};

export const actionSetDraftList = templatePrefix+'/draft-list-set';
export const actionLoadDraftList = templatePrefix+'/draft-list';
export const dispatchLoadDraftList = ()=>{
    return dispatchRequest('draft-list', {}, actionSetDraftList) 
};



export const actionEditTemplate = templatePrefix+'/edit';
export const dispatchEditTemplate = (guid)=>{
    return {type: actionEditTemplate, guid: guid} 
};

export const dispatchEditLoadingTemplate = (data)=>{
    return {type: actionEditTemplate, ...data} 
};

export const actionTemplateSetValue = templatePrefix+'/value';
export const dispatchTemplateSetValue = (data)=>{
    return {type: actionTemplateSetValue, data: data} 
};

export const actionTemplateAddListValue = templatePrefix+'/value/list/add';
export const dispatchTemplateAddListValue = (name, data)=>{
    return {type: actionTemplateAddListValue, name: name, data: data} 
};

export const actionTemplateSetListValue = templatePrefix+'/value/list/set';
export const dispatchTemplateSetListValue = (name, index, data)=>{
    return {type: actionTemplateSetListValue, name: name, index:index, data: data} 
};

export const actionTemplateRemoveListValue = templatePrefix+'/value/list/remove';
export const dispatchTemplateRemoveListValue = (name, index)=>{
    return {type: actionTemplateRemoveListValue, name: name, index:index} 
};


export const actionTemplateSetDraftResult = templatePrefix+'/set-draft-result';
export const dispatchTemplateSetDraft = (url, content, guid, template, user)=>{
    const meta = {'File-User': user, 'File-Guid': guid, 'File-Template': template};
    return dispatchUpload(url, content, meta, actionTemplateSetDraftResult)
};

export const actionTemplateSetDocumentResult = templatePrefix+'/set-document-result';
export const dispatchTemplateSetDocument = (url, content, guid, template, user)=>{
    const meta = {'File-User': user, 'File-Guid': guid, 'File-Template': template};
    return dispatchUpload(url, content, meta, actionTemplateSetDocumentResult)
};

export const actionTemplateResultSave = templatePrefix+'/result';
export const dispatchTemplateAfterSave = ()=>{
    return {type: actionTemplateResultSave, data: {}} 
}

export const actionDraftGetURLs = templatePrefix+'/get-draft-urls';
export const dispatchDraftGetURLs = (guid, template)=>{
    const data = {guid:guid, template: template};
    return dispatchRequest('draft-urls', data, actionDraftGetURLs)
};

export const actionDownloadDraft = templatePrefix+'/download-draft-buffer';
export const dispatchDownloadDraft = (url)=>{
    return dispatchDownload(url, actionDownloadDraft) 
}

export const dispatchClearDownloadDraft = ()=>{
    return {type: actionDownloadDraft, buffer: null}  
}

export const actionTemplateWaitList = templatePrefix+'/set-template-wait';
export const dispatchTemplateWaitList = (add, remove)=>{
   return {type: actionTemplateWaitList, add: add, remove: remove}  
}

export const actionDeleteDraftResult = templatePrefix+'/delete-draft-result';
export const dispatchDeleteDraft = (guid, template)=>{
   const data = {guid: guid, template: template};
   return dispatchRequest('draft-remove', data, actionDeleteDraftResult)
}






