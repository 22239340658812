import React from "react";

import { Segment, Label, Button, Portal, Header, Dimmer, Dropdown, Icon } from 'semantic-ui-react'


import { DocUserField } from './DocUserField';

const segmentStyle=(media)=>{
    const result={
                left: '20%',
                position: 'fixed',
                top: '20%',
                width: '60%',
                zIndex: 1500,
              }
    if (media==='mobile') Object.assign(result, {left:0, top:0, width: '100%'})
    else if (media==='tablet') Object.assign(result, {left:'5%', top:'5%', width: '90%'})
        
    return result
    
}

const routeGroups=[
{key:'and', value: 'and', text: 'Все', icon: <Icon name='users'/>},
{key:'or', value: 'or', text: 'Любой из', icon: <Icon name='user'/>},
{key:'queue', value: 'queue', text: 'По очереди', icon: <Icon name='tasks'/>},
]

const signTypes=[
{key:'0', value: 0, text: <> <Icon name='certificate' color='olive'/>УНЭП</>},
{key:'1', value: 1, text: <><Icon name='certificate' color='violet'/>УКЭП</>},
]

const defaultUser = {guid:null, signType:0}

const RouteEditor=(props)=>{
    const [openLoading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({users:[defaultUser], group: 'queue'});
    
    React.useEffect(()=> {
       if (!props.value) {
           setLoading(false);
           return;
       }
       const val = {...props.value, users: props.value.users && [...props.value.users] || []}
       if (!val.group) val.group='queue';
       if (!val.users) val.users=[];
       val.users.push(defaultUser)
       setData(val)
       if (openLoading) setTimeout(()=>setLoading(false), 200); //Эмуляция загрузки
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.value]);
    
    
    const handleGroup=(e, {value})=>{
        setData(d=>{ return {...d, group:value} })
    }
    
    const handleUserChange=(value, index)=>{        
        setData(d=>{
            const users=[...d.users];
            users[index]={...users[index], ...value};
            if (index===users.length-1) users.push(defaultUser);
            return {...d, users:users} 
        })        
    }
    
    const handleSignTypeChange=(value, index)=>{        
        setData(d=>{
            const users=[...d.users];
            users[index]={...users[index], signType: value};
            return {...d, users:users} 
        })        
    }
    
    const removeUser=(index)=>{
        setData(d=>{
            d.users.splice(index, 1);
            return {...d, users: d.users} 
        })
    }
    
    const handleSave=()=>{
        const  usersUnique =  data.users.filter((el, ind) => ind === data.users.findIndex(e=>e.guid===el.guid));
        props.onSave({...data, users: usersUnique.filter(e=>!!e.guid && (!props.author || props.author && e.guid!==props.user) )})
        props.onClose()
    }
    
    const groupIcon = routeGroups.find(e=>e.value===data.group).icon
    
    const users = data.users.map((e, index)=>{
        const last = index===data.users.length-1;
        return <div key={index} style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{width: '100%'}}>
                <DocUserField onChange={(e,{value})=>handleUserChange(value, index)} value={e} options={{fluid:true}}/>
            </div> 
            <Dropdown style={{width: '10.5em'}}  selection compact
                    options={signTypes} value={e.signType} disabled={!e.guid}
                    onChange={(e,{value})=>handleSignTypeChange(value, index)}/>
            <Button basic icon style={{flexGrow: 1}} onClick={()=>removeUser(index)} disabled={last}>
                <Icon color='red' name='trash'/>
            </Button>
        </div>
    })
    
    return <div style={{position: 'fixed',  opacity: 0.3,
                        top: '0',left: '0',bottom: '0',right: '0',
                        zIndex: '10'}}>
            <Dimmer active/>
            <Portal onClose={props.onClose} open closeOnPortalMouseLeave={false} closeOnDocumentClick={false}>
            <Segment style={segmentStyle(props.media)} loading={openLoading}>
             { props.header && <Header>{props.header}</Header>}
             
             <div style={{marginBottom: '0.7em'}}> 
                {groupIcon}
                <Dropdown inline options={routeGroups} value={data.group} onChange={handleGroup} size='big'/>             
             </div>
             
             {users}
             
               <Button style={{marginTop: '1rem'}}
                content='Отмена'                
                onClick={()=>props.onClose()}
              />
              <Button
                content='Сохранить'
                color='blue'
                onClick={handleSave}
              />
            </Segment>
          </Portal>
     </div>
}


export const DocRoute=props=> {
    const [isEdit, setEdit] = React.useState(false);
    
    const group = props.value && props.value.group || 'queue';
    const groupData = routeGroups.find(e=>e.value===group)
    const groupLabel = <Label>{groupData.icon} {groupData.text}: </Label>
    
    const labels=(props.value && props.value.users ||[]).map(e=>{
        if(!e.info) return null;
        const fio=e.info.family+' '+e.info.name.charAt(0)+'.'+(e.info.patronymic && e.info.patronymic.charAt(0)+'.');
        return <Label key={e.guid} basic color='grey'>
                <Icon name='certificate' color={(e.signType===1) ? 'violet' : 'olive'}/>
                {fio}
            </Label>
    })
    
    const handleSave=(value)=>{
        props.onChange(null, {value: value});
        setEdit(false);
    }
    
    const author= props.author ? <Label basic color='grey'><Icon name='certificate' color='olive'/> {props.fioUser}</Label>: null

    return <div className={(props.required ? 'required': '')+' field'}>
            <label>{props.label}</label>
            <Segment className='route-segment' onClick={()=> setEdit(true)}>
                {groupLabel} {author} {labels}
            </Segment>
            {isEdit && <RouteEditor 
                    value={props.value} 
                    media={props.media} 
                    header={props.label} 
                    author={props.author}
                    user={props.user}
                    onClose={()=>setEdit(false)} 
                    onSave={handleSave}/>
           }
        </div>
  
}