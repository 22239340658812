import React from "react";
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    dispatchLogout, dispatchResetLastLoad,
    dispatchCryptoProCheckSystem,
    dispatchLoadTemplates,
    dispatchLoadDraftList,
    dispatchPinCodeMode,
    dispatchSetListType
} from '../store/actions';

import { InstallPWA } from './InstallPWA';
import { SetPassword } from './SetPassword';
import { Profile } from './Profile';
import { Setting } from './Setting';
import { CreateKey } from './CreateKey';
import { DocList } from './DocList';
import { DocForm } from './forms/DocForm';
import { CreateMenu } from './forms/CreateMenu';
import { DocDrafts } from './forms/DocDrafts';
import { PinCode } from './PinCode';


import {
    Dropdown,
    Confirm,
    Menu,
    Segment,
    Icon
} from 'semantic-ui-react'


const mapStateToProps = (store) => {
    const data = store.user.data || {}

    const info = data.info;
    const fioUser = info && info.family + ' ' + info.name.charAt(0) + '.' + (info.patronymic && info.patronymic.charAt(0) + '.');

    return {
        info: data.info,
        name: fioUser,
        data: data,
        listType: store.docs.listType,
        isSetPrivateKey: data.private_key || false,
        setPassword: store.user.setPassword || false,
        cryptoProError: store.cryptoPro.error,
        cryptoSystem: store.cryptoPro.system,
        cryptoPlugin: store.cryptoPro.plugin,
        loading: store.loading,
        isEditForm: !!store.templates.edit,
        updateTemplates: (new Date(store.templates.updateList)) < (new Date()),
        updateDraft: (new Date(store.templates.updateDraft)) < (new Date()),
        draftCount: store.templates.draft.length,
        menuClass: store.media == 'desktop' ? '' : 'menu-big',
        isMobile: store.media === 'mobile' || store.media === 'tablet',
        inputPin: store.user.inputPin || false,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(dispatchLogout('')),
        reload: () => dispatch(dispatchResetLastLoad()),
        checkSystem: () => dispatch(dispatchCryptoProCheckSystem()),
        openPinSetting: () => dispatch(dispatchPinCodeMode({ mode: 'first-setting' })),
        loadTemplates: () => dispatch(dispatchLoadTemplates()),
        loadDrafts: () => dispatch(dispatchLoadDraftList()),
        setListType: (listType) => dispatch(dispatchSetListType(listType)),
    };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const MainPage = reduxConnect(props => {
    const [visibleConfirmExit, showConfirmExit] = React.useState(false)
    const [visibleProfile, showProfile] = React.useState(false)
    const [visibleSetPassword, showSetPassword] = React.useState(false)
    const [visibleSetPrivateKey, showSetPrivateKey] = React.useState(false)
    const [subMenu, setSubMenu] = React.useState('sign')

    const navigate = useNavigate();
    const currentLocation = useLocation();
    if (currentLocation.pathname.indexOf("logout") > 0 && !visibleConfirmExit) {
        showConfirmExit(true);

    }
    const logout = (success) => {
        if (success) {
            props.logout();
        }
        showConfirmExit(false);
        navigate("/" + currentLocation.pathname.split('/')[1], { replace: true })
    }


    React.useEffect(() => {
        props.checkSystem();
        if (props.inputPin) {
            props.openPinSetting()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {    //Обновление шаблонов     
        if (props.updateTemplates) props.loadTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.updateTemplates])

    React.useEffect(() => {    //Обновление черновиков     
        if (props.updateDraft) props.loadDrafts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.updateDraft])

    React.useEffect(() => {
        showSetPassword(props.setPassword)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.setPassword])

    const handleSubMenu = (e, { name }) => {
        setSubMenu(name)
    }


    const userIcon = <Icon name='user' color={props.isSetPrivateKey ? 'green' : 'red'} style={{ paddingLeft: '1em' }} />

    return <div className=''
        style={{ display: 'flex', width: '100%', height: '100vh', flexDirection: 'column', position: 'relative' }}>
        <Menu fixed='top' inverted size='large' style={{ position: 'relative' }}>
            <Menu.Menu position='left' size='large' >
                <Dropdown item simple text='Мои документы' closeOnChange disabled={props.loading}
                    icon={<Icon size='large' name='file pdf' color='blue' style={{ paddingLeft: '1em' }} />}
                    labeled button className='icon'>
                    <Dropdown.Menu className={props.menuClass}>
                        <CreateMenu />
                        <DocDrafts />
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={() => props.reload()}>
                            <Icon color='green' name='sync' />
                            Обновить
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>

            <Menu.Menu position='right' >

                <Dropdown item simple text={props.name} icon={userIcon} disabled={props.loading}
                    labeled button className='icon' >
                    <Dropdown.Menu className={props.menuClass}>
                        <Dropdown.Header>&nbsp;</Dropdown.Header>
                        <Dropdown.Item onClick={() => showProfile(true)}>
                            <Icon name='user' />
                            Профиль
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => showSetPassword(true)}>
                            <Icon name='key' />
                            Сменить пароль
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => showSetPrivateKey(true)}>
                            <Icon name={props.isSetPrivateKey ? 'certificate' : 'warning sign'}
                                color={!props.isSetPrivateKey ? 'red' : null}
                                className={!props.isSetPrivateKey ? 'blink' : ''} />
                            Установить подпись
                        </Dropdown.Item>

                        <Setting />

                        <InstallPWA />

                        <Dropdown.Item onClick={() => showConfirmExit(true)}>
                            <Icon name='sign-out' />
                            Выход
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
        <Menu style={{ margin: '0 5px' }} className={props.menuClass} >
            <Menu.Item className='submenu' disabled={props.loading}
                name='sign'
                active={subMenu === 'sign'}
                onClick={handleSubMenu}
            ><Icon name='pencil' color={subMenu === 'sign' ? 'blue' : null} />
                На подпись
            </Menu.Item>
            <Menu.Item className='submenu' disabled={props.loading}
                name='archive'
                active={subMenu === 'archive'}
                onClick={handleSubMenu}
            ><Icon name='archive' />
                Архив
            </Menu.Item>

            {!props.isMobile &&
                <Menu.Menu position="right">
                    <Menu.Item icon
                        active={props.listType === 1}
                        onClick={() => props.setListType(1)}>
                        <Icon name='list' />
                    </Menu.Item>
                    <Menu.Item icon
                        active={props.listType === 0}
                        onClick={() => props.setListType(0)}>
                        <Icon name='th' />
                    </Menu.Item>
                </Menu.Menu>
            }
        </Menu>

        <Segment style={{ position: 'relative', flexGrow: '1', margin: '5px', overflow: 'auto' }}>
            <DocList statusDoc={[1, 2]} archive={subMenu === 'archive'} />
        </Segment>

        <Confirm size='mini'
            open={visibleConfirmExit}
            onCancel={() => logout(false)}
            onConfirm={() => logout(true)}
            header='Завершение работы' content='Завершить работу с приложением?'
            cancelButton='Отмена' confirmButton='Да'
        />

        {visibleProfile && <Profile onClose={() => showProfile(false)} />}
        {visibleSetPassword && <SetPassword onClose={() => showSetPassword(false)} />}
        {visibleSetPrivateKey && <CreateKey onClose={() => showSetPrivateKey(false)} />}

        {props.isEditForm && <DocForm />}

        <PinCode />

    </div>;

})