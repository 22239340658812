import React from "react";
import { connect } from 'react-redux';

import { Menu, Icon, Segment } from 'semantic-ui-react'

import { fSort } from './fSort'
import { DocPage as Page } from './DocPage';

const mapStateToProps = (store, props) => {
    const pages=store.templates.edit.pages || {};
    return {
        loading: store.loading,
        items: Object.keys(pages).map(e=>{return {key: e, index: pages[e].index||0, title:pages[e].title, icon:pages[e].icon}}).sort(fSort)
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocPages=reduxConnect(props=> {
    const [subMenu, setSubMenu]= React.useState(props.items[0].key)    

    const handleSubMenu=(e, {name})=>{
        setSubMenu(name)
    }

    const showMenu = props.items.length>1;
    
    const items=props.items.map(e=>{
        const icon=(e.icon) ? <Icon name={e.icon.name} color={ e.icon.color || null} /> : null;
        return <Menu.Item 
              name={e.key}
              key={e.key}
              active={subMenu===e.key}
              onClick={handleSubMenu}
            >{icon}
                {e.title}
            </Menu.Item>
    })
       
    return  <Segment basic style={{display: 'flex', flexGrow: '1', overflow: 'auto', flexFlow: 'column', padding:0, marginTop: '0.1em'}}>
        <Segment style={{display: 'block', flexGrow: '1', marginBottom: '0', borderBottom: showMenu ? 'none' : null}}>
            <Page page={subMenu}/>
        </Segment>
        {showMenu && <Menu  style={{margin: '0px', borderTop: 'none'}}>
            {items}
        </Menu>}
        </Segment>
})