import React from "react";
import { connect } from 'react-redux';
import { dispatchEditTemplate } from '../../store/actions';

import { Dropdown, Icon } from 'semantic-ui-react'

const mapStateToProps = (store, props) => { 
    return {
        templates: store.templates.list.map(e=>{return {guid: e.form.guid, icon: e.form.icon, name: e.form.name} }),
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      editTemplate: (guid)=>dispatch(dispatchEditTemplate(guid, true)),      
  };
};

export const CreateMenu=connect(mapStateToProps, mapDispatchToProps)(props=> { 
    if (props.templates.length===0) return null

     const  templates = props.templates.map(e=>{
        return <Dropdown.Item key={e.guid} onClick={()=>props.editTemplate(e.guid)}>
            <Icon color={e.icon.color || null} name={e.icon.name || null} style={{paddingLeft: '1em', paddingRight: '0.5em'}}/>
                {e.name}
        </Dropdown.Item>
    });
    return <>
    <Dropdown.Header><Icon name='plus' color='green'/>Создать</Dropdown.Header>
    {templates}
    </>
})