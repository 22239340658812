export function formatDateTime(date, s=false)
{
	let d=new Date(date)

	return ("0" + d.getDate()).slice(-2) + "." 
		+ ("0"+(d.getMonth()+1)).slice(-2) + "." 
		+ d.getFullYear() + " " 
		+ ("0" + d.getHours()).slice(-2) + ":" 
		+ ("0" + d.getMinutes()).slice(-2) 
		+ ((s) ? ":" + ("0" + d.getSeconds()).slice(-2) : "");
}

export function formatDate(date)
{
	let d=new Date(date)	
	return ("0" + d.getDate()).slice(-2) + "." 
		+ ("0"+(d.getMonth()+1)).slice(-2) + "." 
		+ d.getFullYear()
}

export function formatDateMySQL(date, T=false)
{
	let d=new Date(date)	
	return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2)
        + ((T) ? "T" : " ")
		+ ("0" + d.getHours()).slice(-2) + ":" 
		+ ("0" + d.getMinutes()).slice(-2)+ ":" 
		+ ("0" + d.getSeconds()).slice(-2);
}