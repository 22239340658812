import { 
actionCryptoProSetData, 
} from  './actions'


const keyCertId = 'certId';

const initialState = {
    system: false,
    plugin: false,
    error: '',
    certificates: [],
    findCertificates: false,
};

export const reducerCryptoPro=(state = initialState, action) => {
    switch (action.type) {
        case actionCryptoProSetData:
          if (action.data.certId) sessionStorage.setItem(keyCertId, action.data.certId)
              else if (!state.certId) action.data.certId = sessionStorage.getItem(keyCertId)
          return { ...state, ...action.data };
        
        default:
          return {...state};
    }
}