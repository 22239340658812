import React from "react";
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useMediaQuery from "./useMediaQuery";

import { 
    dispatchVersionBackend,
    dispatchVersionCreateDocs,
    dispatchGetToken, 
    dispatchSetLocation, 
    dispatchLoadListStorage, 
    dispatchMediaType, 
    dispatchQRCodeTokenLogin 
} from '../store/actions';
import { LoginForm } from './LoginForm';
import { MainPage } from './MainPage';
import { isDemo } from '../store/const';


import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;


//import { Segment, Loader, Dimmer } from 'semantic-ui-react'


const mapStateToProps = (store) => {    
    return {
        token: store.user.token        
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      getVersion:()=>{dispatch(dispatchVersionBackend()); dispatch(dispatchVersionCreateDocs())},
      loadToken: ()=> dispatch(dispatchGetToken()),
      setLocation: (path)=>dispatch(dispatchSetLocation(path)),
      loadListStorage: ()=> dispatch(dispatchLoadListStorage()),
      setMediaType: (media)=>dispatch(dispatchMediaType(media)),  
      tokenLogin: (token)=> dispatch(dispatchQRCodeTokenLogin(token)),      
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const MainFrame=reduxConnect(props=> { 
     const currentLocation = useLocation()
     const runNavigate = useNavigate();
     
     const [isFirstLoad, setFirstLoad] = React.useState(false)
     
     const isDesktop = useMediaQuery('(min-width: 799px)');   
     const isTablet = useMediaQuery('(min-width: 600px)');
     const media = isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile';
     console.log(media)
     
     React.useEffect(()=>{
         props.loadToken();
         props.getVersion();
         setFirstLoad(true);
         props.loadListStorage();
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])
     
     React.useEffect(()=>{
         props.setMediaType(media)
     }, [media]) 
     
     React.useEffect(()=>{
         const appPath = isDemo() ? currentLocation.pathname : '/'+window.location.hostname.split('.')[0];
         props.setLocation(appPath);
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [currentLocation.pathname])
     
     React.useEffect(()=>{
          const paramsToken = (new URL('https://host/'+currentLocation.search)).searchParams.get('token')
          if(paramsToken){             
             props.tokenLogin(paramsToken)
             runNavigate( currentLocation.pathname); 
          }
     }, [currentLocation.search])
     
     React.useEffect(()=>{
            //console.log(props.token)
     }, [props.token])
    
    
    if (!isFirstLoad) return null;
    
    if (!props.token) return <LoginForm />;
    
    return <MainPage/>;

})