// for large strings, use this from https://stackoverflow.com/a/49124600
const buff_to_base64 = (buff) => btoa(
  new Uint8Array(buff).reduce(
    (data, byte) => data + String.fromCharCode(byte), ''
  )
);

function buf_to_str(buf) {
    return String.fromCharCode.apply(null, new Uint8Array(buf));
}

const base64_to_buf = (b64) =>
  Uint8Array.from(atob(b64), (c) => c.charCodeAt(null));

const enc = new TextEncoder();
const dec = new TextDecoder();

const getPasswordKey = (password) =>
  window.crypto.subtle.importKey("raw", enc.encode(password), "PBKDF2", false, [
    "deriveKey",
  ]);

const deriveKey = (passwordKey, salt, keyUsage) =>
  window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 250000,
      hash: "SHA-256",
    },
    passwordKey,
    { name: "AES-GCM", length: 256 },
    false,
    keyUsage
  );

const encryptData = async(secretData, password)=> {
  try {
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const passwordKey = await getPasswordKey(password);
    const aesKey = await deriveKey(passwordKey, salt, ["encrypt"]);
    const encryptedContent = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      aesKey,
      enc.encode(secretData)
    );

    const encryptedContentArr = new Uint8Array(encryptedContent);
    let buff = new Uint8Array(
      salt.byteLength + iv.byteLength + encryptedContentArr.byteLength
    );
    buff.set(salt, 0);
    buff.set(iv, salt.byteLength);
    buff.set(encryptedContentArr, salt.byteLength + iv.byteLength);
    const base64Buff = buff_to_base64(buff);
    return base64Buff;
  } catch (e) {
    console.log(`Error - ${e}`);
    return "";
  }
}

const  decryptData =async(encryptedData, password)=> {
  try {
    const encryptedDataBuff = base64_to_buf(encryptedData);
    const salt = encryptedDataBuff.slice(0, 16);
    const iv = encryptedDataBuff.slice(16, 16 + 12);
    const data = encryptedDataBuff.slice(16 + 12);
    const passwordKey = await getPasswordKey(password);
    const aesKey = await deriveKey(passwordKey, salt, ["decrypt"]);
    const decryptedContent = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      aesKey,
      data
    );
    return dec.decode(decryptedContent);
  } catch (e) {
    console.log(`Error - ${e}`);
    return "";
  }
}

export const createKeys=async(pin)=>{
    const options={
        name: "RSA-OAEP",
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
    }
    
    const keys = await window.crypto.subtle.generateKey(options, true, ["encrypt", "decrypt"] )
    
    const exportedPrivate = await window.crypto.subtle.exportKey("pkcs8", keys.privateKey);
    const exportedPrivateAsBase64 = buff_to_base64(exportedPrivate);
    //const privateKey = `-----BEGIN PRIVATE KEY-----\n${exportedPrivateAsBase64}\n-----END PRIVATE KEY-----`;
    const privateKey = await encryptData(exportedPrivateAsBase64, pin);
    
    const exported = await window.crypto.subtle.exportKey("spki", keys.publicKey);
    const exportedAsBase64 = buff_to_base64(exported);
    //const publicKey = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
    const publicKey = exportedAsBase64;
    
    return {publicKey: publicKey, privateKey: privateKey}
}

export const decryptToken=async(token, savedKey, pin)=>{
    const data = base64_to_buf(token)
   
    const pkcs8PrivateKey = await decryptData(savedKey.substr(36), pin)
    
    const algorithm={
        name: "RSA-OAEP",
        hash: "SHA-256",
    }
    const privateKey = await window.crypto.subtle.importKey("pkcs8", base64_to_buf(pkcs8PrivateKey), algorithm, false, ['decrypt']);
   
    const result = await window.crypto.subtle.decrypt(algorithm, privateKey, data)
    return buf_to_str(result)
}