import { 
actionGetToken, 
actionSetToken, 
actionUserLogin,
actionSetLocation,
actionSetUserProps,
actionLogout,
actionAfterSetPassword,
actionAfterSetPrivateKey,
actionSetPush,
actionSetPushServerResult,
actionAfterChangeLogin,
actionAfterSendMobileID,
actionSetAuthToken,
actionPinCodeMode } from  './actions'


const keyToken = 'auth-id';
const keyUser = 'userData';

const clearStorage=()=>{
    let n = sessionStorage.length;
    while(n--) 
      sessionStorage.removeItem(sessionStorage.key(n))    
}

const initialState = {
    token: '',
    path: 'share',
    error: null,
    sendCode: false,
    push: {enable: false },
    pinCodeMode: {mode: null},
    pinSet:!!localStorage.getItem('pin'),
};

export const reducerUser=(state = initialState, action) => {
    switch (action.type) {
        case actionSetUserProps:
          return { ...state, ...action.data };
          
        case actionGetToken:
          return { ...state, 
            token: sessionStorage.getItem(keyToken) || '',  
            data: JSON.parse(sessionStorage.getItem(keyUser) || "{}"),
            };
            
        case actionPinCodeMode:
            return { ...state, pinCodeMode: {...state.pinCodeMode, ...action.data }};
           
        case actionAfterSendMobileID:
            const errorPart = state.requestMobileID && (state.requestMobileID.errorPart || 0 )+1            
            const requestMobileID = (action.data.result) ? {id: action.data.guid} : {message: action.data.message, errorPart: errorPart || 1}
            return { ...state, requestMobileID: requestMobileID}
        
        case actionSetAuthToken:
            return { ...state, authToken: action.data }
        
        case actionSetToken:
          sessionStorage.setItem(keyToken, action.token)
          return { ...state, token: action.token };
          
        case actionLogout:
          clearStorage();
          return { ...state, data:{}, token:'' };
        
        case actionSetLocation:          
          return { ...state, path: action.path };
          
        case actionUserLogin:
          const upd = {}
          upd.error=null;
          upd.errorCode=0;
          upd.openID={};
          
          if (!action.data.result) {
            upd.error = action.data.message
            upd.errorCode = action.data.code
            return {...state, ...upd};
          }
          upd.pinCodeMode={mode:null};
          upd.sendCode = action.data.send_code || false
          if(action.data.token){              
              upd.token = action.data.token
              sessionStorage.setItem(keyToken, upd.token)              
          }
          upd.setPassword = action.data.set_password
          if (action.data.data){
              upd.data = action.data.data;
              sessionStorage.setItem(keyUser, JSON.stringify(action.data.data))
          }
          upd.inputPin = ['token', 'webauthn'].indexOf(action.data.auth)===-1;

          return {...state, ...upd};
        
        case actionAfterSetPassword:
            return {...state, 
                setPassword: !action.data.result, 
                successSetPassword: (action.data.result) ? 'done' : action.data.message};
        
        case actionAfterSetPrivateKey:
            const userData = state.data || {}
            if (action.data.result) userData.private_key=true;
            return {...state, ...userData, successPrivateKey: (action.data.result) ? 'done' : action.data.message};
        
        case actionAfterChangeLogin:            
            if (action.data.result){
                state.data.name = action.data.login;
                state.changeLoginError = '';
                sessionStorage.setItem(keyUser, JSON.stringify(state.data))
            }else{
                state.changeLoginError = action.data.message;
            }
            return {...state }
        
        case actionSetPush:
            return {...state, push: {...state.push, ...action.data}}
            
        case actionSetPushServerResult:
            return {...state, push: {...state.push, resultSave: action.data}}
        
        default:
          return {...state};
    }
}