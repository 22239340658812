import React from "react";
import { connect } from 'react-redux';

import { Icon, Dropdown } from 'semantic-ui-react'

import { dispatchCryptoProSetData } from '../store/actions';
import { formatDate } from './funcDate';

const mapStateToProps = (store) => { 
    const userData = store.user.data || {};
    return {       
        info: userData.info || {},
        cryptoProError: store.cryptoPro.error,
        cryptoSystem: store.cryptoPro.system,
        cryptoPlugin: store.cryptoPro.plugin,
        findCertificates: store.cryptoPro.findCertificates,
        certificates: store.cryptoPro.certificates || [],
        certId: store.cryptoPro.certId,
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
       setCertificate: (certId)=>dispatch(dispatchCryptoProSetData({certId: certId})),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const SelectCertificate=reduxConnect(props=> { 
   if (!(props.cryptoSystem && props.cryptoPlugin)) return props.cryptoProError;
   if (props.findCertificates) return <><Icon loading name='spinner' />Поиск...</>
    
    const certs = props.certificates.map(e=> {
        return {key: e.subjectKeyId, value: e.subjectKeyId, text: e.subject.commonName, description: 'до '+formatDate(e.notAfter)}
    });
    
    return <Dropdown
                placeholder='Выберите сертификат'
                fluid
                selection
                options={certs}
                value = {props.certId}
                onChange={(e, {value})=> props.setCertificate(value)}
              />
    
       
   
})