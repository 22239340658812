import React from "react";
import { connect } from 'react-redux';

import { 
    dispatchSimpleRequest,
    dispatchResetSimpleRequest,
} from  '../../store/actions';

const printHTML=(html)=>{
  const WinPrint = window.open('','','left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0');
  WinPrint.document.write(html);
  WinPrint.document.close();
  WinPrint.focus();
  WinPrint.print();
  WinPrint.close();
}


const mapStateToProps = (store, props) => {
    return {        
        values: store.templates.edit.values, 
        template: store.templates.edit.template,
        funcPrintData: store.templates.edit.set_print_data,        
        lists: store.templates.edit.lists,
        requestId: store.simpleRequest.requestId,
        html: store.simpleRequest && store.simpleRequest.html,        
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
      print: (data)=>dispatch(dispatchSimpleRequest('ext/print', data)),
      clear: ()=>dispatch(dispatchResetSimpleRequest()),
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const PrintDraft=reduxConnect(props=> { 
    const [running, setRun] = React.useState(false);
    
    React.useEffect(()=> {
        const data = {values: props.values, template: props.template}
        
        if (props.funcPrintData){            
            try {
                const func = new Function('data', 'lists', props.funcPrintData);
                data.print_data = func(props.values, props.lists) || null;
            } catch (err) {
                alert(err)
            }
            
        }
        
        props.print(data);
        setRun(true)
        return ()=> props.clear();
    }, []);
    
    React.useEffect(()=> {
        if(running){
            if (props.html) printHTML(props.html);
            props.onClose();
        }
    }, [props.requestId])
    
    return null

})