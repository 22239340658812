import { v4 as uuid4 } from 'uuid';

import { 
    actionSetTemplates,
    actionEditTemplate, 
    actionTemplateSetValue,
    actionTemplateAddListValue,
    actionTemplateSetListValue,
    actionTemplateRemoveListValue,
    actionTemplateResultSave,
    actionSetDraftList,
    actionDownloadDraft,
    actionDraftGetURLs,
    actionTemplateSetDraftResult,
    actionTemplateSetDocumentResult,
    actionTemplateWaitList,
    actionDeleteDraftResult,
    actionAddUsersCache,
    
} from  './actions'


const keyTemplates = 'templates';
const keyDraft = 'draft-list';
const keyUsersCache = 'users-cache';

const initialState = {
    ...(JSON.parse(sessionStorage.getItem(keyTemplates)) || {list:[], updateList: new Date('1970-01-01')}),
    ...(JSON.parse(sessionStorage.getItem(keyDraft)) || {draft:[], updateDraft: new Date('1970-01-01')}),
    usersCache: (JSON.parse(sessionStorage.getItem(keyUsersCache)) || []),
    wait: {},
};


/*export const reducerTemplates=(state, action,  f )=>{
    const res = reducerTemplates1(state, action)
    console.log(action.type, action)
    console.log(res)
    return res
}*/

const changeFunction=(pages, name)=>{    
    let func = null
    Object.keys(pages).forEach(page=>{
        const field = pages[page].fields[name];
        if ( field && field.change)
            try{
                func = new Function('data', field.change);            
            }catch(err){
                console.warn(err)
            }
    })
    return func
}

export const reducerTemplates=(state = initialState, action)=>{
    const editData = state.edit;
    let funcTableChange = null;
    switch (action.type) {
        case actionSetTemplates: 
           if (action.data.result) {
                const now=new Date();
                const saveData = {list: action.data.templates, updateList: now.setMinutes(now.getMinutes() + 15)};
                sessionStorage.setItem(keyTemplates, JSON.stringify(saveData));
                return {...state, ...saveData }
           }
           return {...state};  
        
        case actionSetDraftList: 
           if (action.data.result) {
                const now1=new Date();
                const saveDataDraft = {draft: action.data.draft, updateDraft: now1.setMinutes(now1.getMinutes() + 15)};
                sessionStorage.setItem(keyDraft, JSON.stringify(saveDataDraft));
                return {...state, ...saveDataDraft }
           }
           return {...state};
        
        case actionEditTemplate:
           if (!action.guid) return {...state, edit: null }  
           const template = state.list.find(e=>e.form.guid===action.guid);           
           const data = template.form;
           return {...state, 
                edit: {...data, 
                    values: action.values || {}, 
                    guidDoc: action.guidDoc || uuid4(), 
                    template: {key: template.key},
                    dateSave: action.dateSave || null, 
                }
          };  
        
        case actionTemplateSetValue:           
           if (!editData.values) editData.values = {};  
           Object.assign(editData.values, action.data);
           editData.change=true;
           return {...state, ...editData};
           
        case actionTemplateAddListValue:
            if (!editData.values) editData.values = {[action.name]: []};
            if (!editData.values[action.name]) editData.values[action.name] = []
            editData.values[action.name].push(action.data);
            editData.change=true;        
            
            funcTableChange = changeFunction(editData.pages, action.name);
            if (funcTableChange) {
                try{
                    funcTableChange(editData.values)
                } catch(err){
                    alert(action.type+": "+err)
                }
             }
            
            return {...state, ...editData};
        
        case actionTemplateSetListValue:
            editData.values[action.name][action.index] = action.data;
            editData.change=true;
            
            funcTableChange = changeFunction(editData.pages, action.name);
            if (funcTableChange) {
                try{
                    funcTableChange(editData.values)
                } catch(err){
                    alert(action.type+": "+err)
                }
             }
            
            return {...state, ...editData};
        
        case actionTemplateRemoveListValue:
            editData.values[action.name].splice(action.index, 1);
            editData.change=true;
            
            funcTableChange = changeFunction(editData.pages, action.name);
            if (funcTableChange) {
                try{
                    funcTableChange(editData.values)
                } catch(err){
                    alert(action.type+": "+err)
                }
             }
            
            return {...state, ...editData};
           
        case actionTemplateResultSave:
            editData.resultSave = action.data
            if (action.data.date) editData.dateSave = new Date(action.data.date)
            return {...state};
        
        case actionTemplateSetDraftResult:
            editData.resultSave = action.data
            editData.dateSave = new Date()
            return {...state};
        
        case actionTemplateSetDocumentResult:
            editData.resultSave = action.data;
            editData.resultSave.saveAsDocument = action.data.result;
            return {...state};
        
        case actionDraftGetURLs:        
            editData.urls = action.data.urls || {};
            return {...state};
        
        case actionDownloadDraft:
            return {...state, download: action.buffer};
            
        case actionTemplateWaitList:
            const wait = state.wait;
            if (action.add){
                action.add.forEach(e=> wait[e.guid] = e.url)
            }
            if (action.remove){
                action.remove.forEach(e=>{
                    if(wait[e]) delete wait[e]
                })
            }
            return {...state, wait: {...wait}}
            
        case actionDeleteDraftResult:            
            return {...state, updateDraft: new Date('1970-01-01'), edit: null }
            
        case actionAddUsersCache:
            const usersCache = [...action.user, ...state.usersCache];
            const  usersCacheUnique =  usersCache.filter((el, ind) => ind === usersCache.findIndex(e=>e.guid===el.guid));
            sessionStorage.setItem(keyUsersCache, JSON.stringify(usersCacheUnique));
            return {...state, usersCache: usersCacheUnique};
        
        default:
          return {...state};
    }
}

