import React from 'react'
import { connect } from 'react-redux';

import { Button, Segment, Icon, Image, Portal, Header, Form, Message, Progress } from 'semantic-ui-react'

import imgMobileId from '../images/mobile-id.png'

import {  dispatchSendMobileID, dispatchSetAuthToken, dispatchMobileIDTokenLogin } from '../store/actions';
import {  AgreementDocument } from './AgreementDocument';



const phoneFormat = (s, plus = true) => {
  const startsWith = plus ? '+7' : '8';

  let phone = s.replace(/[^0-9]/g, '');
  if (phone.startsWith('7') && plus) {
    phone = phone.substr(1);
  }
  if (phone.startsWith('8')) {
    phone = phone.substr(1);
  }

  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `${startsWith} ($1) $2 $3 $4`);
};

const checkStatus=async (requestID, callback)=>{
    const opt = {
            method: 'POST', 
            body: JSON.stringify({request_id: requestID})
         }
         
    fetch('/mobile-id/status', opt)
         .then(response => {
             if (response.status!==200) {
                 callback(false, String(response.status))
                 return null
             }
             return response.json()
         })
         .then(data => {
             callback(true, data)
         })
         .catch(e => {callback(false, String(e))})
}

const styleVisible=(v)=>{
    return v ? {} : {display: 'none'}
}

const mapStateToProps = (store) => { 
    return {
        media: store.media,
        errorPart: store.user.requestMobileID && store.user.requestMobileID.errorPart || -1,
        error: store.user.requestMobileID && store.user.requestMobileID.message || null,
        requestID: store.user.requestMobileID && store.user.requestMobileID.id || null,
        authToken: store.user.authToken || null,
        loading: store.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
     sendMobileID: (msisdn)=> dispatch(dispatchSendMobileID(msisdn)), 
     login: (token)=> dispatch(dispatchMobileIDTokenLogin(token)), 
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const MobileID=reduxConnect(props=> {
  const [visible, openMobileID] = React.useState(false) ;
  
  const [error, setError] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [checkMsisdn, setCheckMsisdn] = React.useState(false);
  
  
  const [disabledButton, disableButton] = React.useState(true) 
  const [msisdn, setMsisdn] = React.useState('')
  const [agreement, setAgreement] = React.useState(false)
  
  const isMobile = props.media==='mobile' || props.media==='tablet';
  
  
  React.useEffect(()=>{
         const phone = props.msisdn.replace(/[^0-9]/g,"");
         setMsisdn(phone);
         disableButton(!agreement || phone.length!==11);
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [props.msisdn])
     
  React.useEffect(()=>setProgress(0), [props.errorPart])
     
  React.useEffect(()=>{
         setCheckMsisdn(false);
         if (!props.requestID) {setProgress(0);   return; }
         setError(null);
         setProgress(1)
         const interval = setInterval(() => {
            checkStatus(props.requestID, (result, data)=>{
                if(!result) {
                   clearInterval(interval);
                   setProgress(0)
                }else{
                    if (data && data.result!=null){
                        clearInterval(interval);
                        setProgress(0)
                        if (data.status===1) {
                            openMobileID(false)
                            props.login(data.token)
                        }else if (data.status===3) setError('Время ожидания истекло')
                        else if (data.status===4) setError('Ошибка обработки запроса')
                        else if (data.status===2) setError('Пользователь отклонил запрос')                         
                    }else setProgress(p=>p+2)
                }
            })
        }, 2000); 
        return () => clearInterval(interval);        
     }, [props.requestID])
     
  React.useEffect(()=>{
         const phone = msisdn.replace(/[^0-9]/g,"");
         disableButton(!agreement || phone.length!==11);
     }, [msisdn])
     
  const handleChange=(e, {value})=>{      
      setMsisdn(phoneFormat(value));
  }
  
  const handleChangeCheckbox=(e, {checked})=>{
      setAgreement(checked);
      const phone = msisdn.replace(/[^0-9]/g,"");
      disableButton(!checked || phone.length!==11);
      if (!checked) openMobileID(false);
  }
  
  const handleClick=()=>{
      setProgress(5);
      setCheckMsisdn(true);
      props.sendMobileID(msisdn.replace(/[^0-9]/g,""))
  }
  
  if (!visible) {
      return <Button basic icon color='grey' onClick={()=>openMobileID(true)} style={{display: 'flex',justifyContent: 'center'}}>
                <div >
                    <Image src={imgMobileId} style={{width: 'auto', height: '1.3em', marginRight: '0.4em', marginTop: '-0.2em'}} />
                </div>
                <div>Мобильный ID</div>
            </Button>
  }  
  const loading = props.loading || progress>0;
  const zIndex = (loading) ? 3000: 100;
  
  const winStyle = {width: props.media==='mobile' ? '100%' : '30em', margin: '0 auto', top: '10%'}
  
  return <Segment style={{position: 'fixed',top: '5%',left: '5%',right: '5%',bottom: '5%', zIndex: zIndex}}>
        <div style={{position: 'absolute', zIndex: '10', right: isMobile ? '.2rem' : '1.2rem',top: isMobile ? '.2rem' : '1.3rem'}}>
            <Button size='mini' basic icon circular onClick={()=>openMobileID(false)} disabled={loading}>
                <Icon color='red' name='close'/>
            </Button>
        </div>  
        
        <Segment textAlign='center' basic={props.media==='mobile'}
            style={{width: props.media==='mobile' ? '100%' : '30em', margin: '0 auto', top: '10%'}}>
            
            <div style={styleVisible(!loading)}>
                <Header>Введите свой номер, чтобы войти в сервис "Подписание документов"</Header>
                
                <Form.Input type='tel' size='big' value={msisdn} onChange={handleChange} placeholder='Номер телефона'/>
                <Form.Button type='submit' color='yellow' size='large' fluid disabled={disabledButton} 
                        style={{display: 'flex',justifyContent: 'center'}}
                        onClick={handleClick}>
                    <div >
                        <Image src={imgMobileId} style={{width: 'auto', height: '1.3em', marginRight: '0.4em', marginTop: '-0.2em'}} />
                    </div>
                    <div style={{color:'black'}}>Войти с Мобильным ID</div>
                </Form.Button>
                <span style={{fontSize: '0.8em', color: '#777'}}>
                    <p style={{margin: '0'}}>Вход через вашего оператора связи.</p>
                    <p style={{margin: '0'}}>Данные защищены шифрованием.</p>
                </span>
                <div style={{display: 'flex',justifyContent: 'left', marginTop: '1em'}}>
                    <Form.Checkbox checked={agreement} onChange={handleChangeCheckbox}/>
                    <div style={{marginLeft: '0.8em', textAlign:'left'}}>
                        Подтверждаю согласие с условиями
                        <AgreementDocument url='/src/mobile_id.txt'
                                text=' пользовательского соглашения '/>                       
                        и <AgreementDocument url='/src/policy.txt'
                                text='политики обработки персональных данных'/>.
                    </div>
                </div>
                <Message error visible={!!props.error}>{props.error}</Message>
                <Message error visible={!!error}>{error}</Message>
            </div>
            
            <div style={styleVisible(loading)}>
                <Header>Процесс авторизации через Мобильный ID</Header>
                <Progress value={progress} total={70} warning>
                    {checkMsisdn ? "Проверка номера пользователя..." : "Ожидание подтверждения..."}
                </Progress>
                <div style={styleVisible(!checkMsisdn)}>
                    <div>Мы отправили Push-уведомление на номер</div> 
                    <div style={{color: 'green', fontWeight: 'bold'}}> {msisdn} </div>
                    <div>Подтвердите вход в приложение.</div>
                </div>
            </div>
            
        </Segment>
        
        
    </Segment>

})