import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/configureStore'; 
import { MainFrame } from './components/Main'; 
import { FrontPage } from './components/FrontPage'; 


import 'semantic-ui-css/semantic.min.css';
import './App.css';

const App=()=> {
  return (
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path='*' element={<MainFrame />} />
                <Route path='/' element={<FrontPage />} />
            </Routes>   
        </BrowserRouter>
    </Provider>
  );
}

export default App;
