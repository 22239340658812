import React from 'react'
import { connect } from 'react-redux';

import { Segment, Button, Icon, Input, Header, Message} from 'semantic-ui-react'

import { dispatchPinCodeMode, dispatchSendPinKey, dispatchGetPinToken, dispatchTokenLogin, dispatchSetUserProps } from '../store/actions';
import { PinInput } from './PinInput';
import { createKeys, decryptToken } from './cryptoKeys';


const pinSize=4;
const initDigits = Array.from({length:pinSize}, (e)=>'');


const setKeys=(pin, callback)=>{
    createKeys(pin).then(r=>callback(r))
}


const mapStateToProps = (store) => { 
    return {
        media: store.media,
        size: store.media=='desktop' ? null : 'big',
        loading: store.loading, 
        mode: store.user.pinCodeMode.mode,
        auth: store.user.pinCodeMode.mode==='auth',
        requestResult: store.user.pinCodeMode.result || null,
        requestId: store.user.pinCodeMode.request_id || null,        
        privateKey: store.user.pinCodeMode.privateKey || null,
        userId: store.user.data.guid,
        pinToken: (store.user.pinCodeMode.data && store.user.pinCodeMode.data.pin_token)  || null,
        error: store.user.error,
        errorCode: store.user.errorCode,
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
     setPrivateKey: (privateKey)=>dispatch(dispatchPinCodeMode({privateKey:privateKey})), 
     sendPublicKey: (publicKey)=>dispatch(dispatchSendPinKey(publicKey)),
     closeAndClear: ()=>dispatch(dispatchPinCodeMode({mode:null, request_id:null, result:null, privateKey: null, data: null})),
     getPinToken: (user)=>dispatch(dispatchGetPinToken(user)),
     login: (token)=>dispatch(dispatchTokenLogin(token)),
     closePinMode: ()=>dispatch(dispatchPinCodeMode({mode:null})),
     setPinStatus: ()=> dispatch(dispatchSetUserProps({pinSet:true})),
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);



export const PinCode=reduxConnect(props=> {
    const [loading, setLoading] = React.useState(false);
    const [digits, setDigits] = React.useState(initDigits);    
    const [digits1, setDigits1] = React.useState(initDigits);
    const [page, setPage] = React.useState(0);
    const [authError, setError] = React.useState('');
    const firstInputRef = React.useRef();
    //console.log(props)
    
    React.useEffect(()=> {        
        if (props.auth) {            
            const pinKey = localStorage.getItem('pin', null);
            if (pinKey) {                
                props.getPinToken(pinKey.slice(0,36))
            }
        }
        return ()=>props.closeAndClear();
    } ,[])
    
       
    React.useEffect(()=> {
        if (props.requestId && props.requestResult){ 
            localStorage.setItem('pin', props.userId+props.privateKey);
            props.setPinStatus()
            props.closeAndClear();
            setPage(0);                
            setDigits(initDigits);            
        }       
    } , [props.requestId]);
    
    if (props.media==='desktop' || !(props.mode || props.auth) || !window.crypto) return null;
        
    const handleChange=(value)=>{
        setError('');
        if (page===1) setDigits1(value)
            else setDigits(value)
    }
    
    const handleBack=()=>{
        let newPage = page-1;
        if (newPage<0){
            newPage=0;                        
            props.closeAndClear();
        }
        setDigits(initDigits);
        setDigits1(initDigits);
        setPage(newPage);
        firstInputRef.current.focus();        
    }
    const handleForward=(value)=>{
        let newPage = page+1;
        if (newPage>1){
            const pin = value.join('')
            if (pin===digits.join('')) {
                setLoading(true);
                setKeys(pin, (result)=>{
                    props.setPrivateKey(result.privateKey)
                    props.sendPublicKey(result.publicKey);                     
                    setLoading(false);                                     
                })                
            }
            return;
        }
        setDigits1(initDigits);
        setPage(newPage);
        firstInputRef.current.focus();
    }
    
    const handleAuth=(value)=>{        
        const pin = value.join('')
        const pinKey = localStorage.getItem('pin', null);
        decryptToken(props.pinToken, pinKey, pin)
            .then(token=>{
                props.login(token)
                setError('');
                setDigits(initDigits);
            }).catch(e => { 
                setError('Неверный ПИН-код');
                setDigits(initDigits);
                firstInputRef.current.focus();
            });
    }
    
    const pin = digits.join(''),  pin1 = digits1.join('')
    
    if (props.mode==='setting' || props.mode==='first-setting'){
        return <Segment loading={props.loading || loading} className='fullscreen' style={{paddingTop: '3em'}}>
            <Button.Group size={props.size} fluid >
                <Button basic icon color='grey' onClick={handleBack} disabled={props.mode==='first-setting' && page===0}>
                    <Icon name='angle left'/> 
                    &nbsp; Назад
                </Button>
                
                {page===0 && <Button basic icon color='black' onClick={handleForward} disabled={pin.length!==pinSize}>
                    Далее &nbsp;
                    <Icon name='angle right'/>                      
                </Button>}
                
                {page===1 && <Button basic icon color='blue' onClick={handleForward} disabled={pin1!==pin}>
                    Готово &nbsp;
                    <Icon name='checkmark'/>                      
                </Button>}
            </Button.Group>
            <Header textAlign='center'style={{marginTop: '4em'}} >
                {page===0 ? "Введите ПИН-код" : "Повторите ввод ПИН-кода"}
            </Header>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <PinInput onChange={handleChange} 
                    digits={(page===1)? digits1 : digits} 
                    firstInputRef={firstInputRef} 
                    error={page===1 && pin1.length==pinSize && pin1!==pin}
                    onSuccess={handleForward}/>
            </div>
        </Segment>
    }
    if (props.auth){
        
        const closePinInput=()=>{
            
        }
        
        return <Segment basic style={{flexGrow: 1}}>
            <Header textAlign='center' style={{marginTop: '0'}}>Введите ПИН-код</Header>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <PinInput onChange={handleChange}  
                    digits={digits} 
                    firstInputRef={firstInputRef} 
                    error={!!authError}
                    onSuccess={handleAuth}/>                 
            </div>
            <div style={{position: 'absolute', bottom: '2em'}}>
                <Button inverted color='grey' size='big' onClick={()=>props.closePinMode()}>
                     <Icon name='undo'/> 
                     &nbsp; Отмена
                </Button>
            </div>
            {authError && <Message error content={authError}/>}
            {props.error && <Message error content={props.error}/>}
        </Segment>
    }
    return null
})

