import React from "react";
import { connect } from 'react-redux';


import { Button, Message, Form, Modal, Icon, Dropdown } from 'semantic-ui-react'

import { PushCheckbox } from './PushCheckbox';
import {  Webauthn } from './Webauthn';
import {  SetLogin } from './SetLogin';

import {dispatchPinCodeMode } from '../store/actions';



const mapStateToProps = (store, props) => { 
    
    return {
       loading: store.loading,
       errorWebauthn: store.webauthn.error,
       token: store.user.token,
       existBot: !!store.user.data.telegram,
       bot: store.user.data.telegram,
       size: store.media=='desktop' ? null : 'big',
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      openPinSetting: ()=>dispatch(dispatchPinCodeMode({mode:'setting'})),      
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const Setting=reduxConnect(props=> {
    const [visible, setVisible] = React.useState(false);
    const [visiblePIN, setVisiblePIN] = React.useState(false);
    
    React.useEffect(()=> {
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, []);
    

    if(!visible){
        return <Dropdown.Item onClick={()=>setVisible(true)}>
                    <Icon name='setting'/>
                    Настройки
                </Dropdown.Item>
    }
     
    return <><Modal size='mini'
      onClose={() => setVisible(false)}
      open={visible}
    >
      <Modal.Header><Icon name='setting'/>Настройки</Modal.Header>
      <Modal.Content>        
         <Form loading={props.loading} size={props.size}> 
            <SetLogin/>
            <PushCheckbox/>
            <Webauthn mode='reg'/>
            <div>
                <Button basic icon color='grey' onClick={()=>props.openPinSetting()} size={props.size}>
                    <Icon name='key'/> 
                    &nbsp; Установить ПИН-код
                </Button>
            </div>
            {props.existBot && <div style={{marginTop: '1em'}}>
                <Button basic icon color='blue' as='a' href={`https://t.me/${props.bot}?start=${props.token}`} target='_blank' size={props.size}>
                    <Icon name='telegram plane'/> 
                    &nbsp; Уведомлять через <b>Telegram</b>
                </Button>
            </div>}
            
         </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setVisible(false)} disabled={props.loading} size={props.size}>
          Закрыть
        </Button>        
      </Modal.Actions>
      {props.errorWebauthn && <Message error content={props.errorWebauthn}/>}
    </Modal>    
    </>
})