import React from "react";
import { connect } from 'react-redux';
import { dispatchSimpleRequest, dispatchResetSimpleRequest, dispatchAddUsersCache } from '../../store/actions';

import { Form } from 'semantic-ui-react'


const mapStateToProps = (store, props) => {
    
    const items = [...(store.simpleRequest.data||[]), ...store.templates.usersCache];
    const  itemsUnique =  items.filter((el, ind) => ind === items.findIndex(e=>e.guid===el.guid));
    
    return {
        requestId: store.simpleRequest.requestId,
        items: itemsUnique,
        usersCache: store.templates.usersCache,
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
     searchUsers: (data)=>dispatch(dispatchSimpleRequest('search-users', {name: data, hideLoading:true})),
     clear: ()=>dispatch(dispatchResetSimpleRequest()),
     addCache: (user)=>dispatch(dispatchAddUsersCache(user)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocUserField=reduxConnect(props=> {
    const [currentValue, setCurrent] = React.useState('');
    const [searching, setSearching] = React.useState(false);
    const [searchTimeout, setSearchTimeout] = React.useState(null);
    const [valueItems, setValueItems] = React.useState([]);
    
    
    React.useEffect(()=> {
        props.clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);
    
    React.useEffect(()=> {
        if(props.value) setCurrent(props.value.guid);
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.value]);
    
    React.useEffect(()=> {
        setSearching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.items]);
    
    const options = props.items.map(e=>{
        return {key: e.guid, value: e.guid, 
            text: e.family+' '+e.name+' '+e.patronymic,
            description: e.departamen+', '+e.position,
        }
    })
    
    const handleChange=(e, {value})=>{        
        setCurrent(value);
        const item = options.find(e=>e.key===value);
        const result = {guid: value, text: item.text, description: item.description, info: props.items.find(e=>e.guid===value)};
        props.onChange(e, {value: result});
        if(props.onBlur) props.onBlur(e, {value: result});
        props.addCache([result.info])
    }
    
    const handleSearch=(e,{searchQuery})=>{
        if (searchTimeout){  
            clearTimeout(searchTimeout);
        }
        if (!searchQuery) {
            props.clear()
            return 
        }
        const timeout = setTimeout(function() {
            setSearching(true)
            props.searchUsers(searchQuery)
        }, 800);
        setSearchTimeout(timeout)
    }
   
    if (props.value && !options.find(e=>e.key===props.value.guid)){
        options.push({key: props.value.guid, value: props.value.guid, text: props.value.text, description: props.value.description})
    }
    
    
    
    return <Form.Dropdown
            label={props.label} required={props.required || false}
            {...props.options}
            scrolling={false}            
            search
            minCharacters={3}
            loading = {searching}
            disabled = {searching}
            selection
            options={options}
            value={currentValue}
            onSearchChange={handleSearch}
            onChange={handleChange}
          />

})