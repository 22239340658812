import React from "react";
import { connect } from 'react-redux';

import { Form } from 'semantic-ui-react'
import { fSort } from './fSort'
import { DocField as Field } from './DocField';


const mapStateToProps = (store, props) => {
    const page=store.templates.edit.pages[props.page];
    return {
        fields: Object.keys(page.fields).map((e, i)=>{
            return {key: e, index: page.fields[e].index||0, group: page.fields[e].group|| '#not_group_'+i }
        }).sort(fSort),
        groups: page.groups || {},
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocPage=reduxConnect(props=> {
    const groups = []
    props.fields.forEach(e=>{
        if (groups.indexOf(e.group)===-1) groups.push(e.group) 
    })
    
    const form_groups = groups.map(group=>{        
        const fields = props.fields.filter(e=>e.group===group).map(e=>{
            return <Field key={props.page+'/'+e.key} page={props.page} name={e.key}/>
        })    
        if (group.indexOf('#not_group_')!==-1) return fields[0];
        const options = props.groups[group] || {}
        return <Form.Group key={props.page+'/'+group} widths={options.width||16}>{fields}</Form.Group>
    })
    return  <Form>{form_groups}</Form>
})