import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Modal, Button } from 'semantic-ui-react'


export const AgreementDocument=(props)=>{
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState('');
    
    React.useEffect(()=>{
         if (!open) return;             
         fetch(props.url)
            .then(response => response.text())
            .then(data => setText(data));
     }, [open, props.url])
    
    const handleHrefClick = (e) => {
        e.preventDefault();
        setOpen(true)        
    };
    
    if (!open) return <a href='#' onClick={handleHrefClick}>{props.text}</a>
    
    return <Modal open={open} onClose={()=>setOpen(false)}>
        <Modal.Header>*</Modal.Header>
        <Modal.Content scrolling>
          <ReactMarkdown>{text}</ReactMarkdown>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            Закрыть
          </Button>
        </Modal.Actions>
    </Modal>
}