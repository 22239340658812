export const CaptchClientKey='ysc1_fOfx5YrgopvJP4lhWaVt5uIh8Jb12qU0ofRFkJgc013fe8d5';
export const YandexClientID = 'cc31eb33241949d4befd8f1e473caa64';
export const GoogleClientID='1016570709159-e42r7tvkq3s5qrld9utin60v7b4mpmld.apps.googleusercontent.com';
//export const VKClientID='51668526';
export const VKClientID='51668434';

export const appDomain = ['signdocs.ru'];

export const isDemo=()=>{
    const curentDomain = window.location.hostname.split('.').slice(-2).join('.')
    return appDomain.indexOf(curentDomain)===-1
}