import React from "react";
import { connect } from 'react-redux';
import { dispatchTemplateSetValue } from '../../store/actions';

import { Form } from 'semantic-ui-react'
import { fSort } from './fSort'
import { formatDateMySQL, formatDate } from '../funcDate'

import { DocTable } from './DocTable';
import { DocFile } from './DocFile';
import { DocUserField } from './DocUserField';
import { DocRoute } from './DocRoute';


const mapStateToProps = (store, props) => {
    const page=store.templates.edit.pages[props.page];
    const values = store.templates.edit.values || {};
    const lists = store.templates.edit.lists || {};
    
    const fieldItems = page.fields[props.name].items || '#'
    const items = typeof(fieldItems)==='object' ? fieldItems : lists[fieldItems]
    
    const info = store.user.data.info;
    const fioUser=info.family+' '+info.name.charAt(0)+'.'+(info.patronymic && info.patronymic.charAt(0)+'.');
    
    return {
        loading: store.loading,
        isMobile: store.media==='mobile' || store.media==='tablet',
        media: store.media,
        ...page.fields[props.name],
        value: values[props.name],
        default: page.fields[props.name].default,
        options: page.fields[props.name].options || {},
        items: items || null,
        fioUser: fioUser,
        user:store.user.data.guid,
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      setValue: (data)=>dispatch(dispatchTemplateSetValue(data)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocField=reduxConnect(props=> {
    const [currentValue, setCurrent] = React.useState(null);
    const [customOptions, setCustomOptions] = React.useState([]);
    
    
    React.useEffect(()=> {        
        if (props.value===undefined) {
            let defValue = props.default || '';            
            if (props.type==='date' && defValue==='now') defValue=formatDateMySQL(new Date()).slice(0,10);
            else if (props.type==='table' && !props.default) defValue=[]
            else if ((props.type==='select' || props.type==='routes') && !props.default && props.required) {
                defValue=(typeof(props.items[0])==='object') ? Object.keys(props.items[0])[0] : props.items[0];
            }
            props.setValue({[props.name] : defValue });
            setCurrent(defValue)
        }else setCurrent(props.value)
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [props.value]);

    const handleChange=(e, {value})=>setCurrent(value);       
    const handleBlur = (e, data)=>{
        const value = data && data.value;
        props.setValue({[props.name]: value || currentValue})
    }
    const handleSelect = (e, {value})=>props.setValue({[props.name]: value});
    const handleChekbox=(e, {checked})=>props.setValue({[props.name]: checked});  

    const handleAddition = (e, { value }) => {
        setCustomOptions(c=>{ c.push({value: value, text: value}); return c })
        setCurrent(value)
    }    

    
    const width=props.isMobile ? 16 : props.options.width;
    const widthSmall=props.isMobile ? 16 : props.options.width || 5;

    switch (props.type) {
        case 'date':
            return <Form.Input name={props.name} label={props.label} required={props.required || false} onChange={handleChange} onBlur={handleBlur} 
                        type='date' value={currentValue||''}  {...props.options} width={widthSmall}/>
        case 'number':
            return <Form.Input name={props.name} label={props.label} required={props.required || false} onChange={handleChange} onBlur={handleBlur} 
                        type='number' value={currentValue||0} step='any' {...props.options} width={widthSmall} style={{textAlign:"right"}}/>
        case 'textarea':
            return <Form.TextArea name={props.name} label={props.label} required={props.required || false} onChange={handleChange} onBlur={handleBlur} 
                        value={currentValue||''} {...props.options} width={width}/>
        case 'boolean':
            return <Form.Checkbox  name={props.name} label={props.label} required={props.required || false} onChange={handleChekbox} 
                        toggle checked={currentValue||false} {...props.options}/>
        case 'user_routes':            
            return <DocRoute page={props.page} value={props.value||null} media={props.media}
                            author={props.author||false} user={props.user} fioUser={props.fioUser}
                            name={props.name} label={props.label} required={props.required || false} 
                            onChange={handleBlur}/>
        
        case 'routes':        
        case 'select':
            const items=(props.items || []).map(e=>{
                let data = {}
                let key = e, text = e;
                if (typeof(e)==='object'){
                    key = Object.keys(e)[0];
                    data=typeof(e[key])==='object' ? e[key] : {};
                    text = e[key]
                }
                return {key: key, value: key, text: text, ...data};
            })
            
            const useAdd = {};
            if (props.options && props.options.add){
                useAdd.search = true;
                useAdd.allowAdditions = true;
                useAdd.additionLabel = props.options.add;
                useAdd.onAddItem=handleAddition;
            }
            
            return <Form.Select name={props.name} label={props.label} required={props.required || false} onChange={handleSelect}  
                        value={currentValue||''} options={[...customOptions, ...items]} {...props.options} {...useAdd} width={widthSmall} />
        case 'table':
            return <DocTable page={props.page} name={props.name}/>
            
        case 'file':
            return <DocFile page={props.page} name={props.name}/>
        
        case 'user':
            return <DocUserField name={props.name} label={props.label} required={props.required || false} onChange={handleChange} onBlur={handleBlur} 
                        value={currentValue||''} {...props.options} width={width}/>
        
        default:
            return <Form.Input name={props.name} label={props.label} required={props.required || false} onChange={handleChange} onBlur={handleBlur} 
                        value={currentValue||''} {...props.options} width={width}/>
    }

})