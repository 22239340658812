
import { v4 as uuid4 } from 'uuid';

const apiPath='/api'
//const apiPath='/local'

export const sendRequest=(path, token, action, data, callback)=>{
    let httpCode=0;    
    let requestId;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }
    if (token) options.headers['Authorization'] = token
    
    fetch(apiPath+'/'+path.replace("/", "")+'/'+action, options )
    .then(response => {
        httpCode = response.status;
        requestId = response.headers['X-Request-Id'] || uuid4()
        return response.json();
     })
    .then(result => callback(httpCode, {requestId: requestId, ...result}))
    .catch(e => { 
        console.log(e)
        callback(500, {result: false, message: "Ошибка доступа в Интернет"})
    });
}


export const downloadLink=(url, callback)=>{
    let httpCode=0;
    fetch(url)
    .then(response => {
        httpCode = response.status;
        return response.arrayBuffer();;
     })
    .then(buffer => callback(httpCode, buffer))
    .catch(e => { 
        callback(500, "Ошибка доступа в Интернет")
    });
}

export const uploadLink=(url, data, meta, callback)=>{
    let httpCode=0;
    let request_id=null;
    const headers = {}
    if (meta){
        for (const [key, value] of Object.entries(meta)) {
            headers['X-Amz-Meta-'+key] = value;
        }
    }
    const requestOptions = {
    method: 'PUT',
        headers: headers,
        body: data
    };
    
    fetch(url, requestOptions)
    .then(response => {
        httpCode = response.status;        
        request_id = response.headers['X-Amz-Request-Id'] || uuid4()
        return response.text();
     })
    .then(res => callback(httpCode, {result: true, data: meta, request_id: request_id, text: res}))
    .catch(e => {        
        callback(500, {result: false, message: "Ошибка доступа в Интернет"})
    });
}