import React from "react";
import { connect } from 'react-redux';
import { SelectCertificate } from './SelectCertificate';

import { Button, Icon, Modal, Table, Dropdown, Form } from 'semantic-ui-react'





const mapStateToProps = (store) => { 
    const userData = store.user.data || {};
    return {       
        email: userData.email,
        info: userData.info || {},
        name: userData.name,
        organization_name: userData.organization_name,
        phone: userData.phone,
        private_key: userData.private_key,       
        cryptoPro: store.cryptoPro.system && store.cryptoPro.plugin,
        loading: store.loading,
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const Profile=reduxConnect(props=> { 
     //const [data, setData]= React.useState({newPassword:'', confirmPassword:''})
    
       
    const data=[];
    data.push(["Организация", props.organization_name]);
    data.push(["ФИО", props.info.family+" "+props.info.name+" "+props.info.patronymic]);
    data.push(["Логин", props.name]);
    data.push(["E-mail", props.email]);
    data.push(["Телефон", props.phone]);        
    data.push(["Подпись", (props.private_key) ? <Icon name='checkmark' color='green'/> : <><Icon name='warning sign' color='red'/>Не установлена</>]);
    if (props.cryptoPro) data.push(["Сертификат", <SelectCertificate/>]);
    data.push(["Подразделение", props.info.departamen]);
    data.push(["Должность", props.info.position]);
    if (props.info.phone_work) data.push(["Рабочий телефон", props.info.phone_work]);
    if (props.info.rank) data.push(["Рабочий телефон", props.info.rank]);
        
    const cellStyle = {paddingTop: 0, paddingBottom: 0}
         
    return <Modal size='tiny'
      onClose={() => props.onClose()}
      open={true}
    >
      <Modal.Header><Icon name='user'/>Информация о пользователе</Modal.Header>
      <Modal.Content scrolling > 
        <Form loading={props.loading}>
         <Table definition>
          <Table.Body>            
              {data.map((e,i)=> <Table.Row key={i}><Table.Cell width={2} content={e[0]}/><Table.Cell style={cellStyle}>{e[1]}</Table.Cell></Table.Row> )}                        
          </Table.Body>
        </Table>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.onClose()} disabled={props.loading}>
          Закрыть
        </Button>
      </Modal.Actions>
    </Modal>

})