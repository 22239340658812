import React from "react";
import { connect } from 'react-redux';
import { dispatchTemplateRemoveListValue } from '../../store/actions';

import { Table, Segment, Button, Icon } from 'semantic-ui-react'

import { formatDate } from '../funcDate'
import { Confirm } from './Confirm'
import { DocEditRow } from './DocEditRow'

const getItemText=(value,items)=>{
    if (!value) return null;
    const row=items.find(e=>value=== (e && Object.keys(e)[0]));
    if (!row) return value;
    const item=row[Object.keys(row)[0]];
    return typeof(item)==='object' ? item.text : item;
}

const mapStateToProps = (store, props) => { 
    const page=store.templates.edit.pages[props.page];
    const values = store.templates.edit.values || {};
    
    const tmpCols = page.fields[props.name].cols;    
    const lists = store.templates.edit.lists || {};
    const cols = {}
    Object.keys(tmpCols).forEach(key=>{
        const fieldItems = tmpCols[key].items || '#'
        cols[key] = {...tmpCols[key]}
        cols[key].items = typeof(fieldItems)==='object' ? fieldItems : lists[fieldItems]
    })
    return {
        loading: store.loading,
        isMobile: store.media==='mobile' || store.media==='tablet',
        rows: values[props.name]||[],
        cols: cols,
        footer: page.fields[props.name].footer,
        options: page.fields[props.name].options || null,
    }
}


const mapDispatchToProps = (dispatch, props) => {
  return {
      removeRow: (index)=>dispatch(dispatchTemplateRemoveListValue(props.name, index)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocTable=reduxConnect(props=> { 
    const [rowIndex, setRowIndex]=React.useState(0);
    const [deleteDialog, showDelete]=React.useState(false);
    const [editRow, setEditRow]=React.useState(null);
    
    const options = props.options || {}
    
    const footerSums = props.footer &&  props.footer.sum  || [];
    const footerValues = {};
    
    const deleteRow=()=>{
        if (rowIndex<props.rows.length) {
            props.removeRow(rowIndex);
            setRowIndex(Math.max(rowIndex-1, 0))
            showDelete(false);
        }
    }
    
    const header=Object.keys(props.cols).map(key=>{
        footerValues[key] = footerSums.indexOf(key)!==-1 ? 0  : ''
        
        const col=props.cols[key];        
        if (col.hide) return null;
        if (col.hideMobile && props.isMobile) return null;
        return <Table.HeaderCell key={key} width={col.col_options && col.col_options.width||null} >{col.label}</Table.HeaderCell>
    })
    
    const rows=props.rows.map((row, i)=>{
        
        footerSums.forEach(key=>{ footerValues[key] += row[key] || 0})
        
        const cells=Object.keys(props.cols).map(key=>{
            const col=props.cols[key];
            if (col.hide) return null;
            if (col.hideMobile && props.isMobile) return null;
            let value = row[key];
            if (col.type==='row_number') value=i+1;
            else if (col.type==='date') value=formatDate(row[key]);
            else if (col.type==='boolean') value= row[key] ? <Icon name='checkmark' color='green'/>:'';
            else if (col.type==='select') value=getItemText(row[key], col.items);
            else if (col.type==='number' && col.decimal && !col.format) value=Number(row[key]).toFixed(col.decimal);
            
            const col_options=col.col_options || {}
            if (col.format){                
                 try {
                     const func = new Function('value', col.format);
                    value = func(value)
                 } catch (err) {
                    console.warn(key+': ', err)
                 }
            }
          
            return  <Table.Cell key={key} {...col_options} className={i===rowIndex ? 'selected-row': ''}>{value}</Table.Cell>
        })
        return <Table.Row key={'row-'+i} 
                onClick={()=>setRowIndex(i)} 
                onDoubleClick={()=>setEditRow(i)}>
           {cells}
       </Table.Row>
    })
    
    //console.log(footerValues)
    
    const footer = Object.keys(props.cols).map(key=>{
        const col=props.cols[key];
        if (col.hide) return null;
        if (col.hideMobile && props.isMobile) return null;
        const col_options=col.col_options || {}
        if (col.format && footerSums.indexOf(key)!==-1){           
           try {
               const func = new Function('value', col.format);
               footerValues[key] = func(footerValues[key])
           } catch (err){
               console.warn('footer '+key+': ', err)
           }
        }
        return <Table.HeaderCell key={key} {...col_options} >{footerValues[key]}</Table.HeaderCell>
    })
    
    return<div>  
    <Segment style={{margin: '0px', borderBottom: 'none', padding: '1px'}}>
        <Button.Group basic>
            {options.append!==false && <Button basic icon onClick={()=>setEditRow(-1)} >
                    <Icon name='plus' color='green'/>
            </Button>}
            <Button basic icon disabled={props.rows.length===0} onClick={()=>setEditRow(rowIndex)}>
                <Icon name='pencil' color='blue'/>
            </Button>
            {options.remove!==false && <Button disabled/>}
            {options.remove!==false && <Button basic icon disabled={props.rows.length===0} onClick={()=>showDelete(true)}>
                <Icon name='trash' color='red'/>
            </Button>}
        </Button.Group>
    </Segment>
    <Table celled selectable unstackable size='small' style={{marginTop:0}}>
        <Table.Header><Table.Row>{header}</Table.Row></Table.Header>
        <Table.Body>{rows}</Table.Body>
        {props.footer && <Table.Footer><Table.Row>{footer}</Table.Row></Table.Footer>}
    </Table>
    <Confirm 
          open={deleteDialog}
          onCancel={()=>showDelete(false)}
          onConfirm={()=>deleteRow()}
          cancelButton='Нет'
          confirmButton="Да"
          content='Удалить строку?'
        />
    <DocEditRow page={props.page} name={props.name} row={editRow} onClose={()=>setEditRow(null)}/>
   </div>

})