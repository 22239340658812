import React from "react";
import { connect } from 'react-redux';


import { Button, Message, Form, Icon } from 'semantic-ui-react'

import { dispatchChangeLogin, dispatchSetUserProps } from '../store/actions';

const mapStateToProps = (store, props) => { 
    return {
        size: store.media=='desktop' ? null : 'big',
       login: store.user.data.name,
       loading: store.loading,
       error: store.user.changeLoginError || ''
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
       change: (login)=>dispatch(dispatchChangeLogin(login)),
       clear: ()=>dispatch(dispatchSetUserProps({changeLoginError:''}))
  };
};

const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const SetLogin=reduxConnect(props=> {
    const [login, changeLogin] = React.useState("");
    React.useEffect(()=> {
        changeLogin(props.login);
        props.clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, [props.login]);    
    
    const saveable = props.login!=login
    
    const clearValue=()=>{
        changeLogin(props.login);
        props.clear();        
    }
       
    const action=<Button.Group basic attached='left' size={props.size}>
        <Button icon onClick={clearValue}>
            <Icon color='red' name='cancel'/>
        </Button>
        <Button icon onClick={()=>props.change(login)}>
            <Icon color='green' name='checkmark'/>
        </Button>
    </Button.Group>
    
    const error= props.error ? { content: props.error,    pointing: 'below' } : null

    return <Form.Input size={props.size} fluid
            label='Логин' 
            value={login} 
            loading={props.loading}
            disabled={props.loading}
            action={saveable && action}  
            error = {error}
            onChange={(e, {value})=>changeLogin(value)}/>

})