import React from "react";
import { connect } from 'react-redux';
import { dispatchLoadListDocs, dispatchTemplateWaitList, dispatchSetListSort } from '../store/actions';
import { formatDateTime } from './funcDate';
import { DocumentViewer } from './DocumentViewer';
import { DocWait } from './DocWait';
import { SignDocument } from './SignDocument';

import { Grid, Card, Icon, Image, Container, Dimmer, Loader, Segment, Checkbox, Button, Table } from 'semantic-ui-react'

const orderList = (list, name, sortType) => {
    const result = [...list]
    const k = sortType === 'ascending' ? 1 : -1
    result.sort((a, b) => a[name] > b[name] ? k : -k)
    return result
}


const mapStateToProps = (store) => {
    //const tmp=[]
    //for (let i=0; i<30; i++) tmp.push(store.docs.list[0])
    const updateDate = store.docs.lastLoad.setMinutes(store.docs.lastLoad.getMinutes() + 15);
    return {
        update: updateDate < (new Date()),
        list: store.docs.list,
        loading: store.loading,
        docWaiting: Object.keys(store.templates.wait),
        user: store.user.data.guid,
        isMobile: store.media === 'mobile' || store.media === 'tablet',
        listType: store.media !== 'mobile' && (store.docs.listType || 0) || 0,
        listSortName: store.docs.listSort.name,
        listSortType: store.docs.listSort.sort + 'ending',
    }
}


const mapDispatchToProps = (dispatch, props) => {
    return {
        reload: () => dispatch(dispatchLoadListDocs()),
        removeWait: (guids) => dispatch(dispatchTemplateWaitList(null, guids)),
        setSortList: (name, sort) => dispatch(dispatchSetListSort(name, sort)),
    };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const DocList = reduxConnect(props => {
    const [guidDocument, showDocument] = React.useState('');
    const [notActual, setNotActual] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [visibleSignDoc, showSignDoc] = React.useState(false);

    const iconSize = !props.isMobile && props.listType === 0 ? 'large' : null

    React.useEffect(() => {
        if (props.update) props.reload()
        else {
            props.removeWait(props.list.map(e => e.guid))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.update])


    const selectAll = () => {
        setSelected(props.list.filter((e) => e.route_status === 1 && e.status == 1).map(e => e.guid))
    }

    const setCheckedDoc = (guid, checked) => {
        const tmp = [...selected]
        if (checked) tmp.push(guid)
        else tmp.splice(tmp.indexOf(guid), 1);
        setSelected(tmp);
    }

    const closeSign = (res) => {
        if (res == 'done') {
            props.reload();
            setSelected([])
        }
        showSignDoc(false)
    }

    const handleSort = (name) => {
        let sortType = 'asc';
        if (name === props.listSortName) {
            sortType = (props.listSortType === 'ascending') ? 'desc' : 'asc';
        }
        props.setSortList(name, sortType)
    }

    const list = orderList(props.list, props.listSortName, props.listSortType).map((e, i) => {
        if (!notActual && !e.actual) return null;

        const isArchive = (e.route_status > 2 || e.status > 2);
        const isWait = (e.route_status === 1 && e.status == 1) || (e.status == 2 && e.author_id === props.user);
        if (isArchive !== props.archive && !props.archive !== isWait) return null
        const isAuthor = props.user === e.author_id;

        let extra = <Icon name='help circle' />
        if (e.status === 1) {
            extra = <>
                <Icon name='user' color='blue' size={iconSize} /> {e.count}&nbsp;&nbsp;
                <Icon name={e.count - e.signed > 0 ? 'clock' : 'checkmark'} color='green' size={iconSize} />{e.count - e.signed > 0 ? e.count - e.signed : ""}
            </>
        } else if (!e.actual) {
            extra = <>
                <Icon name='trash' color='red' size={iconSize} /> &nbsp;&nbsp; Не актуальный
            </>
        } else if (e.status === 3) {
            extra = <>
                <Icon name='cancel' color='red' size={iconSize} /> &nbsp;&nbsp; Анулированно
            </>
        } else if (e.status === 2) {
            extra = <>
                <Icon name='hand paper' color='red' size={iconSize} /> {e.count}&nbsp;&nbsp;
                <Icon name='clock' color='grey' size={iconSize} /> {e.count - e.signed} &nbsp;&nbsp; Отказ подписания
            </>
        } else if (e.status === 4) {
            extra = <>
                <Icon name='user' color='blue' size={iconSize} /> {e.count}&nbsp;&nbsp;
                <Icon name='checkmark' color='green' size={iconSize} />
                {isAuthor && e.print_status && <Icon name='print' color='grey' size={iconSize} />}
                &nbsp;&nbsp; Завершено
            </>
        }

        let chkSign = null, descrStyle = null;
        if (e.route_status === 1 && e.status == 1) {
            chkSign = <Checkbox
                style={{ position: 'absolute', zIndex: 10, top: '1.5em', left: '1.5em' }}
                onClick={(ev, { checked }) => setCheckedDoc(e.guid, checked)}
                checked={selected.indexOf(e.guid) !== -1}
            />
            descrStyle = { textIndent: '1em' }
        }

        const cardColor = !e.actual ? 'red' : (isAuthor ? 'blue' : null)

        if (props.listType === 0) {
            return <Grid.Column key={i + e.guid} mobile={props.isMobile ? 16 : 5} tablet={5} widescreen={3} largeScreen={4}>
                {chkSign}
                <Card onClick={() => showDocument(e.guid)} color={cardColor} style={{ marginTop: 0 }}>
                    <Image src='' wrapped ui={false} />
                    <Card.Content>
                        <Card.Header></Card.Header>
                        <Card.Description style={descrStyle}>
                            {e.description}
                        </Card.Description>
                        <Card.Meta>Создан: <span className='blue'>{formatDateTime(e.change + "Z", true,)}</span></Card.Meta>
                        <Card.Meta>Автор: <span className='blue'>{e.author}</span></Card.Meta>
                    </Card.Content>
                    <Card.Content extra>
                        {extra}
                    </Card.Content>
                </Card>
            </Grid.Column>
        } else {
            return <Table.Row key={i + e.guid}
                style={{ cursor: 'pointer' }}
                onClick={() => showDocument(e.guid)}>
                <Table.Cell>{extra}</Table.Cell>
                <Table.Cell><div className="truncate-text">{e.description}</div></Table.Cell>
                <Table.Cell>{e.author}</Table.Cell>
                <Table.Cell>{formatDateTime(e.change + "Z", true,)}</Table.Cell>
            </Table.Row>
        }


    })

    props.docWaiting.forEach(e => list.push(<DocWait key={e} guid={e} reload={() => props.reload()} />))

    let groupMenu = null;
    if (selected.length && !props.archive) {
        groupMenu = <div style={{ position: 'absolute', left: 0, top: 0, right: 0, padding: '0.2em', zIndex: 2 }}>
            <Button.Group basic style={{ float: 'right', float: 'right', backgroundColor: '#fff' }}>
                <Button icon onClick={() => showSignDoc(true)}>
                    <Icon name='pencil alternate' color='blue' />
                    {!props.isMobile ? ' Подписать' : null}
                </Button>
                <Button icon onClick={() => selectAll()}>
                    <Icon name='plus square outline' />
                </Button>
                <Button icon onClick={() => setSelected([])}>
                    <Icon name='minus square outline' />
                </Button>
            </Button.Group>
        </div>
    }

    //Сначала подписываем усиленной
    const reinforcedList = props.list.filter(e => e.sign_type === 1).map(e => e.guid)
    const isReinforced = reinforcedList.findIndex(e => selected.indexOf(e) !== -1) !== -1;
    const signList = selected.filter(e => isReinforced ? reinforcedList.indexOf(e) !== -1 : reinforcedList.indexOf(e) === -1)



    return <Container fluid>
        {groupMenu}
        <Dimmer active={props.loading} inverted>
            <Loader inverted content='Загрузка документов' />
        </Dimmer>
        <Grid centered >
            {props.listType === 0 && list}
            {props.listType === 1 &&
                <Table celled singleLine selectable sortable unstackable style={{ padding: '0' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={4} sorted={props.listSortName === 'status' && props.listSortType || null}
                                onClick={() => handleSort('status')}>
                                Статус
                            </Table.HeaderCell>
                            <Table.HeaderCell width={7} sorted={props.listSortName === 'description' && props.listSortType || null}
                                onClick={() => handleSort('description')}>
                                Заголовок
                            </Table.HeaderCell>
                            <Table.HeaderCell width={3} sorted={props.listSortName === 'author' && props.listSortType || null}
                                onClick={() => handleSort('author')}>
                                Автор
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2} sorted={props.listSortName === 'change' && props.listSortType || null}
                                onClick={() => handleSort('change')}>
                                Дата
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {list}
                    </Table.Body>
                </Table>
            }
        </Grid>

        {props.archive &&
            <Segment basic style={{ position: 'sticky', bottom: '-0.2em', padding: 0 }} >
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <Checkbox style={{ padding: '0.2em', backgroundColor: 'white' }} label='Не актуальные'

                        checked={notActual}
                        onChange={(e, { checked }) => setNotActual(checked)} />
                </div>
            </Segment>
        }

        {guidDocument && <DocumentViewer guid={guidDocument} onClose={() => showDocument('')} />}

        {visibleSignDoc &&
            <SignDocument selected={signList} reject={false} cancel={false} returnDoc={false}
                status={1} onClose={closeSign} isReinforced={isReinforced} />
        }
    </Container>

})