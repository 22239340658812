import {sendRequest, downloadLink, uploadLink} from './requests'

import { 
    actionRequest, actionDownload, actionUpload, dispatchLoading, dispatchLogout, 
    actionWebauthnCreate, dispatchWebauthnCreate, actionSaveWebauthn, dispatchSetWebauthnProps, actionSetWebauthnProps, dispatchWebauthnAuth,
    dispatchPinCodeMode,
} from './actions';

import { startRegistration, startAuthentication } from '@simplewebauthn/browser';


export const apiMiddleware=(store)=> {
  
    
  return next => action => {
    if (action.type===actionRequest){
        const {token, path} = store.getState().user;
        
        const hideLoading = action.data.hideLoading || false;
        delete action.data.hideLoading;
        
        if (!hideLoading) store.dispatch(dispatchLoading(true));      
        sendRequest(path, token, action.action, action.data, (code, result)=>{            
            if (!hideLoading) store.dispatch(dispatchLoading(false));
            
            if (code===401 || code===403){
                store.dispatch(dispatchLogout())
            }else  store.dispatch({type: action.callbackAction, data: result})
        });
       
    }else
    if (action.type===actionDownload){
        store.dispatch(dispatchLoading(true));
        downloadLink(action.url, (code, buffer)=>{            
            store.dispatch(dispatchLoading(false));
            store.dispatch({type: action.callbackAction, buffer: buffer})
        });
       
    }else
    if (action.type===actionUpload){
        store.dispatch(dispatchLoading(true));
        uploadLink(action.url, action.data, action.meta, (code, result)=>{            
            store.dispatch(dispatchLoading(false));
            store.dispatch({type: action.callbackAction, data: result})
        });
       
    }else
    if (action.type===actionWebauthnCreate){
        store.dispatch(dispatchLoading(true));
        startRegistration(action.data.options).then(res=>{
            //store.dispatch(dispatchLoading(true));
            store.dispatch(dispatchWebauthnCreate(res))            
        }).catch((error) => {
            store.dispatch(dispatchLoading(false));
            store.dispatch(dispatchSetWebauthnProps({error: error.toString()}))
        })
        
    }else
    if (action.type===actionSaveWebauthn){
        store.dispatch(dispatchSetWebauthnProps({enabled: action.data.result, error: action.data.message || null, guid: action.data.guid || null}))
    }
    else
    if (action.type===actionSetWebauthnProps && action.data.options){
        //store.dispatch(dispatchLoading(true));
        store.dispatch(dispatchSetWebauthnProps({authShow: true}));
        
        startAuthentication(action.data.options).then(res=>{
            //store.dispatch(dispatchLoading(false));
            store.dispatch(dispatchWebauthnAuth({data: res}))
        }).catch((error) => { 
            //store.dispatch(dispatchLoading(false));
            let message=error.toString();
            if(store.getState().user.pinSet){
                store.dispatch(dispatchPinCodeMode({mode:'auth'}));
                message = 'Ошибка авторизации Webauthn. Используйте ПИН-код'
            }                        
            store.dispatch(dispatchSetWebauthnProps({authShow: false, result:false, message: message}))            
        })
    }
    
    
    
    const returnValue = next(action);
    return returnValue;
  }
}