import React from "react";
import { connect } from 'react-redux';

import { Grid, Card, Image, Icon, Progress } from 'semantic-ui-react'

import { formatDate } from './funcDate';

import { dispatchTemplateWaitList } from '../store/actions';


const mapStateToProps = (store, props) => { 
    return {
        loading: store.loading,
        isMobile: store.media==='mobile' || store.media==='tablet',
        url: store.templates.wait[props.guid]
    }
}


const checkLink=(url, callback)=>{
    let httpCode=0;
    fetch(url, { method: 'HEAD' })
    .then(response => {
        httpCode = response.status;
        callback(httpCode, '')
     })
    .catch(e => { 
        callback(500, String(e))
    });
}

const mapDispatchToProps = (dispatch, props) => {
  return {
      remove: ()=>dispatch(dispatchTemplateWaitList(null, [props.guid])),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const DocWait=reduxConnect(props=> { 
    const [percent, setPercent] = React.useState(20);
    React.useEffect(() => {
        const interval = setInterval(() => {
            setPercent(percent => percent + Math.round(percent/4));
            if (props.url) checkLink(props.url, (code, message)=>{
                if(code===200) {
                   clearInterval(interval);
                   setTimeout(()=>{
                       props.reload();
                       props.remove();                       
                   }, 8000)
                }
            })
        }, 2000);
        return () => clearInterval(interval);
    }, []);

     
    return  <Grid.Column mobile={16} tablet={5} widescreen={3} largeScreen={4}>
        <Card >
            <Image src='' wrapped ui={false} />
            <Card.Content>
              <Card.Header></Card.Header>              
              <Card.Description>
                    <Progress  percent={percent} active/>
              </Card.Description>
              <Card.Meta>Создан: <span className='blue'>{formatDate(new Date())}</span></Card.Meta>
              <Card.Meta>Автор: <span className='blue'>Я</span></Card.Meta>
            </Card.Content>
            <Card.Content extra>
                <Icon name='user' color='grey'/> ?&nbsp;&nbsp;
                <Icon name='clock' color='grey' /> ?
            </Card.Content>
          </Card>
    </Grid.Column>

})