import React from "react";
import { connect } from 'react-redux';
import { dispatchSetPassword, dispatchSetUserProps } from '../store/actions';
import { checkPassword } from './checkPassword';



import { Button, Message, Form, Modal, Icon } from 'semantic-ui-react'


const mapStateToProps = (store) => {        
    return {
        successSetPassword: store.user.successSetPassword, 
        loading: store.loading, 
        media: store.media,   
        size: store.media=='desktop' ? null : 'big',        
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      setPassword: (value)=> dispatch(dispatchSetPassword(value)),
      setUserProps: (value)=> dispatch(dispatchSetUserProps(value)),
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const SetPassword=reduxConnect(props=> { 
     const [data, setData]= React.useState({newPassword:'', confirmPassword:''})
     const isMobile = props.media==='mobile' || props.media==='tablet';
     
     React.useEffect(()=> {      
        if (props.successSetPassword==='done'){
            props.setUserProps({successSetPassword: '', setPassword: false})
            props.onClose()
        }      
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, [props.successSetPassword]);
     
     const savePassword=()=>{       
         props.setPassword(data.newPassword)
     }
     
     const handleChange=(e, {name, value})=>{         
         setData({ ...data, [name]: value })
     }
    
    const enableSave = data.newPassword && data.newPassword===data.confirmPassword;
    
    const errors = []
    if (data.newPassword && data.confirmPassword && data.newPassword!==data.confirmPassword)
        errors.push('Пароль и подтверждение не совпадают')
    if (data.newPassword.length>0 && checkPassword(data.newPassword)===0)
        errors.push('Пароль слишком простой. Используйте строчные и прописные символы и цифры')
    
    
    const winStyle={}
    if (isMobile) winStyle.top='5%';
    
    return <Modal size='mini' style={winStyle}
      onClose={() => props.onClose()}
      open={true}
    >
      <Modal.Header><Icon name='key'/> Сменить пароль</Modal.Header>
      <Modal.Content>        
         <Form loading={props.loading} size={props.size}> 
            <Form.Input type='password' 
                label='Новый пароль' placeholder='**********' autoComplete="new-password"
                name='newPassword' value={data.newPassword} onChange={handleChange}/>
            <Form.Input type='password' 
                label='Подтверждение' placeholder='**********' autoComplete="new-password"
                name='confirmPassword' value={data.confirmPassword} onChange={handleChange}/>
          </Form>
          
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => props.onClose()} disabled={props.loading} size={props.size}>
          Отмена
        </Button>
        <Button type='submit' disabled={!enableSave || props.loading} size={props.size}
          content="Записать"
          labelPosition='right'
          icon='key'
          onClick={savePassword}
          positive
        />
      </Modal.Actions>
      {errors.length>0 && <Message error list={errors}/>}
    </Modal>

})