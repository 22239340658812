import React from "react";
import { connect } from 'react-redux';
import { dispatchSetPrivateKey, dispatchSetUserProps } from '../store/actions';



import { Button, Message, Form, Modal, Icon } from 'semantic-ui-react'


const mapStateToProps = (store) => {        
    return {
        successPrivateKey: store.user.successPrivateKey, 
        loading: store.loading,
        media: store.media,
        size: store.media=='desktop' ? null : 'big',        
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
      createPrivateKey: (value)=> dispatch(dispatchSetPrivateKey(value)),
      clear: ()=>dispatch(dispatchSetUserProps({successPrivateKey:null}))
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const CreateKey=reduxConnect(props=> { 
     const [passphrase, setPassphrase]= React.useState('')
     const [error, setError]= React.useState('')
     const isMobile = props.media==='mobile' || props.media==='tablet';
     
     console.log(props)
     
     React.useEffect(()=> {      
        if (props.successPrivateKey==='done')      {
            props.clear()
            props.onClose()
        }
        else if(props.successPrivateKey) setError(props.successPrivateKey)    
        // eslint-disable-next-line react-hooks/exhaustive-deps        
     }, [props.successPrivateKey]);
     
     const save=()=>{       
         props.createPrivateKey(passphrase)
     }
     
     const handleChange=(e, {name, value})=>{         
         setPassphrase(value)
     }
    
    const winStyle={}
    if (isMobile) winStyle.top='5%';
    
    return <Modal size='mini' style={winStyle}
      onClose={() => props.onClose()}
      open={true}
    >
      <Modal.Header><Icon name='certificate'/> Создание подписи</Modal.Header>
      <Modal.Content>        
         <Form loading={props.loading} size={props.size}> 
            <Form.Input type='password' 
                label='Парольная фраза' placeholder='**********' autoComplete="one-time-code"
                name='passphrase' value={passphrase} onChange={handleChange}/>
          </Form>
          {error && <Message error content={error}/>}
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => props.onClose()} disabled={props.loading} size={props.size}>
          Отмена
        </Button>
        <Button type='submit' disabled={!passphrase} size={props.size}
          content="Создать"
          labelPosition='right'
          icon='key'
          onClick={save}
          positive
        />
      </Modal.Actions>
      
    </Modal>

})