export const checkPassword=(password)=> {
    const s_letters = "qwertyuiopasdfghjklzxcvbnmабвгдеёжзийклмнопрстуфхцчшщъыьэюя"; // Буквы в нижнем регистре
    const b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNMАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ"; // Буквы в верхнем регистре
    const digits = "0123456789"; // Цифры
    const specials = "!@#$%^&*()_-+=\\|/.,:;[]{}"; // Спецсимволы
    let is_s = false; // Есть ли в пароле буквы в нижнем регистре
    let is_b = false; // Есть ли в пароле буквы в верхнем регистре
    let is_d = false; // Есть ли в пароле цифры
    let is_sp = false; // Есть ли в пароле спецсимволы
    for (let i = 0; i < password.length; i++) {
      /* Проверяем каждый символ пароля на принадлежность к тому или иному типу */
      if (!is_s && s_letters.indexOf(password[i]) !== -1) is_s = true;
      else if (!is_b && b_letters.indexOf(password[i]) !== -1) is_b = true;
      else if (!is_d && digits.indexOf(password[i]) !== -1) is_d = true;
      else if (!is_sp && specials.indexOf(password[i]) !== -1) is_sp = true;
    }
    let rating = 0;
    let passwordStrength = 0;
    if (is_s) rating++; // Если в пароле есть символы в нижнем регистре, то увеличиваем рейтинг сложности
    if (is_b) rating++; // Если в пароле есть символы в верхнем регистре, то увеличиваем рейтинг сложности
    if (is_d) rating++; // Если в пароле есть цифры, то увеличиваем рейтинг сложности
    if (is_sp) rating++; // Если в пароле есть спецсимволы, то увеличиваем рейтинг сложности
    /* Далее идёт анализ длины пароля и полученного рейтинга, и на основании этого готовится текстовое описание сложности пароля */
    if (password.length < 6 && rating < 3) passwordStrength = 0;
    else if (password.length < 6 && rating >= 3) passwordStrength = 1;
    else if (password.length >= 8 && rating < 3) passwordStrength = 1;
    else if (password.length >= 8 && rating >= 3) passwordStrength = 2;
    else if (password.length >= 6 && rating === 1) passwordStrength = 0;
    else if (password.length >= 6 && rating > 1 && rating < 4) passwordStrength = 1;
    else if (password.length >= 6 && rating === 4) passwordStrength = 2;
    return passwordStrength
  }