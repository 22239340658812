import { 
actionLogout,
actionLoadListDocs,
actionSetListType,
actionSetListSort,
actionResetLastLoad,
actionLoadListStorage, 
actionSetDocument,
actionSignDocument,
actionSetPrintStatus
} from  './actions'


const keyListDoc = 'doc-list';
const keyListSort = 'listSort';

const initialState = {
    list: [],
    lastLoad: new Date('1970-01-01'),
    data: {},
    listType: Number(localStorage.getItem('listType')) || 0,
    listSort: JSON.parse(localStorage.getItem(keyListSort, null)) || {name:'change', sort: 'asc'},
};

const savedDocuments=()=>{
    const result = {}
    Object.keys(sessionStorage).forEach(key=>{
        if (key.indexOf('doc-')===0){
            const value = JSON.parse(sessionStorage.getItem(key));
            if (value.guid){
                value.lastLoad = new Date(value.lastLoad)
                result[value.guid] = value;
            }
        } 
    })
    return result
}

export const reducerDoc=(state = initialState, action) => {
    switch (action.type) {
        case actionLogout:
          return { ...initialState };         
          
        case actionLoadListDocs:
          if (action.data.result){
            const newList = {list: action.data.docs, lastLoad: new Date()}; 
            sessionStorage.setItem(keyListDoc, JSON.stringify(newList))
            return { ...state, ...newList, error: null };
          } else return { ...state, error: action.data.message }
          
        case actionResetLastLoad:
          return { ...state, lastLoad: new Date('1970-01-01') };
          
        case actionLoadListStorage:
            const savedList = JSON.parse(sessionStorage.getItem(keyListDoc) || "{}");
            const savedData = savedDocuments()
            return { ...state, ...savedList, data: savedData, lastLoad: new Date(savedList.lastLoad || initialState.lastLoad)};
        
        case actionSetListType:
            localStorage.setItem('listType', action.data) 
            return {...state, listType: action.data}
        
        case actionSetListSort:
            localStorage.setItem(keyListSort, JSON.stringify(action.data)) 
            return {...state, listSort: {...action.data}}

            
        case actionSetDocument:
            if (!action.data.result) return {...state}; 
            const docsData = {...state.data}
            const summary = {}
            action.data.docs.forEach(e=>{
                const val = {...e, lastLoad: new Date()};
                docsData[e.guid] = val;
                sessionStorage.setItem('doc-'+e.guid, JSON.stringify(val));
                const item = state.list.find(l=>l.guid===e.guid);
                item.count = e.routes.reduce((s,v)=>s+((!v.user) ? 0 : 1), 0);
                item.signed = e.routes.reduce((s,v)=>s+((!!v.user && v.status===4) ? 1 : 0), 0);
                item.status = e.status;
                item.actual = e.actual;
                item.route_status = e.route_status;
            });
            sessionStorage.setItem(keyListDoc, JSON.stringify({list: state.list, lastLoad: new Date()}))            
            return { ...state, data: docsData, successSignDocument: null }
            
        case actionSignDocument:
            let signResults = action.data.result;
            const signMessages = [action.data.message]
            
            if (action.data.result) {
               Object.entries(action.data.docs).forEach(entries=>{
                    if (typeof(state.data[entries[0]])!=='object') return null; //При групповой подписи загруженнго документа может и не быть
                    
                    state.data[entries[0]].lastLoad = new Date('1970-01-01');
                    if (typeof(entries[1])==='object'){
                        signResults =signResults && entries[1].result;
                        signMessages.push(entries[1].message)
                    }
               })
            };            
            state.successSignDocument = (signResults) ? 'done' : signMessages.join(';');
            return { ...state }
        
        case actionSetPrintStatus:
            if (!action.data.result) return {...state};

            const doc = state.data[action.data.guid];
            doc.print_status = action.data.status;
            sessionStorage.setItem('doc-'+doc.guid, JSON.stringify(doc));

            const item = state.list.find(l=>l.guid===doc.guid);
            item.print_status = doc.print_status;
            sessionStorage.setItem(keyListDoc, JSON.stringify({list: state.list, lastLoad: new Date()}))

           return { ...state }

        default:
          return {...state};
    }
}