import { combineReducers } from 'redux';
import { reducerUser } from './User';
import { reducerDoc } from './Doc';
import { reducerCryptoPro } from './CryptoPro';
import { reducerWebauthn } from './Webauthn';
import { reducerTemplates } from './Templates';

import {
    actionLoading, 
    actionMediaType, 
    actionSimpleRequest, 
    actionResetSimpleRequest, 
    actionVersionBackend,
    actionVersionCreateDocs
} from  './actions'

const reducerLoading=(state = false, action) => {    
    if (action.type===actionLoading) return action.data
    return state
}

const reducerVersionBackend=(state = {backend: '0.0.0', createdocs:'0.0.0'}, action) => {    
    if (action.type===actionVersionBackend) return {...state, backend: action.data.version}
    if (action.type===actionVersionCreateDocs) return {...state, createdocs: action.data.version}
    return {...state}
}

const reducerMediaType=(state = 'desktop', action) => {    
    if (action.type===actionMediaType) return action.media
    return state
}



const reducerSimpleRequest=(state = {}, action) => {    
    if (action.type===actionSimpleRequest) return action.data
    if (action.type===actionResetSimpleRequest) return {}
    return state
}



export const rootReducer = combineReducers({
  version: reducerVersionBackend,
  user: reducerUser,
  simpleRequest: reducerSimpleRequest,
  docs: reducerDoc,
  loading: reducerLoading,
  media: reducerMediaType,
  cryptoPro: reducerCryptoPro,
  webauthn: reducerWebauthn,
  templates: reducerTemplates,
});