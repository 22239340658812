import React from "react";
import { connect } from 'react-redux';

import { Document, Page } from 'react-pdf';


import { Button, Icon, Modal, Divider, Tab, Segment, Dropdown, Portal, Dimmer, Confirm } from 'semantic-ui-react'
import { dispatchLoadDocument, 
    dispatchCancelRejectSignDocument,
    dispatchResetCancelSignDocument,
    dispatchNotRelevantSignDocument, 
    dispatchSetPrintStatus,
} from '../store/actions';

import { SignRoutes } from './SignRoutes';
import { SignDocument } from './SignDocument';
import { formatDate } from './funcDate';


const stampHeight=80;
const stampWidth=255;

const drawStamp=(ctx, x, y, data, isVoid)=>{
   ctx.strokeRect(x, y, stampWidth, stampHeight);

   ctx.textAlign = 'center'
   ctx.fillText("ДОКУМЕНТ ПОДПИСАН ЭЛЕКТРОННОЙ ПОДПИСЬЮ", x+stampWidth/2,y+15, stampWidth-20); 
   
   ctx.textAlign = 'left'
   ctx.font = "10px arial";
   //ctx.fillText("Сертификат:", x+5,y+28);
   ctx.fillText("Владелец:", x+5,y+45);
   ctx.fillText("Действителен:", x+5,y+62);
   ctx.fillText("Алгоритм:", x+5,y+75);
   
   ctx.font = "10px arial";
   ctx.fillText(data.serial_number, x+5,y+28);
   
   ctx.font = "11px arial";
   ctx.fillText("c "+formatDate(data.begin)+" по "+formatDate(data.end), x+80,y+62);
   ctx.fillText(data.signature_algorithm, x+80,y+75, stampWidth-80);
   ctx.fillText(data.name, x+60,y+45, stampWidth-60);
}

const setVoid=(canvasList)=>{
    //for 
    
}

const setStamp=(stamps, isVoid)=>{
   //const stamps = []
   //for (let i=0; i<15; i++) stamps.push(d[0])
   if(!stamps.length) return;
   
   //const canvas = document.querySelector('canvas:last-of-type');
   const canvasList = document.querySelectorAll('canvas');
   if (!canvasList) return;
   const canvas = canvasList[canvasList.length-1]
   
   if (!canvas || !canvas.getContext) return;
   
   const ctx = canvas.getContext("2d");
   ctx.strokeStyle  = '#0000FF'; 
   ctx.fillStyle = "#0000FF";
   ctx.font = "14px arial";
   
  
   const cols = Math.trunc(canvas.width/(stampWidth+5));
   const rows = Math.trunc(stamps.length/cols)+((stamps.length%cols===0) ? 0 : 1);
   
  
   const startY = canvas.height-10-(stampHeight+5)*(rows+1)
   
   let i=0;
   for (let r=1; r<=rows; r++)
       for (let c=1; c<=cols; c++){
           if (i>stamps.length-1) break
           const x=50+(stampWidth+5)*(c-1);
           const y=startY+(stampHeight+5)*r;
           drawStamp(ctx, x, y, stamps[i], isVoid);
           i++;
       }
   
   if (isVoid) setVoid(canvasList);
}



const printCanvas=(name, callback)=>{
    
       
    const canvas = document.getElementsByClassName('react-pdf__Page__canvas');
    if (!canvas.length) return;
    
   
    const portret = canvas[0].clientWidth<canvas[0].clientHeight;
    const width = portret ? 210 : 297;
    const height = portret ? 297 : 210;
    const orientation = portret ?  'portrait' : 'landscape' 
    
    const pages = [];
    for (let i=0; i<canvas.length; i++){
        pages.push(`<img src="${canvas[i].toDataURL()}" style="width: 100%;">`)
    }
    let windowContent = `<!DOCTYPE html>
    <html>
    <head>
        <title>${name}</title>
        <style>
        @page {
          size: A4 ${orientation};
          margin: 0;
        }
        @media print {
          html, body {
            width: ${width}mm;
            height: ${height}mm;
          } 
        </style>
    </head>
    <body>
    ${pages.join('')}
    </body>
    </html>`;
    //let printWin = window.open('','','width=340,height=260');
    let printWin = window.open();
    printWin.document.open();
    printWin.document.write(windowContent);
    printWin.document.addEventListener('load', function() {
        printWin.focus();
        printWin.print();
        printWin.document.close();
        printWin.close();
        callback()            
    }, true);
}

const MyPDF=(props)=>{
  const [numPages, setNumPages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);

    

  React.useEffect(()=> setLoading(true) , [])

  /*React.useEffect(()=>{ Хотел использовать для кэширования, но компонент сам это делает
     if(props.url){
            
     }
  }, [props.url])*/
  
  const onLoadPageSuccess=(page)=>{
      if (numPages===page){
        setStamp(props.stamps, props.isVoid); 
      }
      
  }

  const onDocumentLoadSuccess=({numPages}) =>{      
    setNumPages(numPages);
    setLoading(false);
   
  }
  
  const onDocumentLoadError=() =>{
    setNumPages(0);
    setLoading(false);
  }

  const width = props.media==='mobile' ? 1000 : 1400
    
  const pages=[];
  for (let i=1; i<=numPages; i++){
    pages.push(<Page className="pdf-page" 
            key={i} pageNumber={i} renderTextLayer={false} 
            renderAnnotationLayer={false} width={width} onRenderSuccess={()=>onLoadPageSuccess(i)}/>
     )
    pages.push(<Divider key={'d-'+i} horizontal style={{marginTop: '0.2em'}}>Страница {i} из {numPages} </Divider>)
  }

  return (
    <Segment basic loading={loading} style={{backgroundColor: 'rgb(231 231 231)', margin: 0}}>       
        <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
          {pages}
        </Document>       
    </Segment>
  );
}

const signStatus = {
    none: 0,  // НеНачат
    wait: 1,  // ТребуетсяПодписание
    edit: 2,  // ТребуетсяУточнение
    cancel: 3,  // Аннулирован
    done: 4,  // Завершен
    lose: 5,  // ЗакрытПринудительно
}

const buttonVisible =(user, doc, routes)=>{
    const result={
        btnSign: false, //Подписать
        btnReturn: false, //ВернутьВРаботу
        btnReject: false, //ОтказатьсяОтПодписания
        btnCancelReject: false, //ОтменитьОтказОтПодписания
        btnCancel: false, //Аннулирование
        btnResetCancel: false, //ОтменитьАннулирование
        btnNotRelevant: false, //ПереместитьВНеактуальные
        btnRelevant: false, //СделатьАктуальным - пока не используем
    }
    
    const rightCancel = user===doc.author_guid && doc.cloud; //Есть право аннулирования
    
    
    if (!doc.actual) {
        result.btnRelevant = true
    
    }else if (doc.status === signStatus.none) {
        if (routes.find(e=> e.user===user && e.status===signStatus.wait)) {
            result.btnSign=true;        
        }
    
    }else if (doc.status === signStatus.wait){
        if(routes.find(e=> e.user===user && e.status===signStatus.wait)){
            result.btnSign=true;        
            result.btnReject=true; 
        }
        if (rightCancel) result.btnCancel=true;
    
    }else if (doc.status === signStatus.edit){
        if(routes.find(e=> e.user===user && e.status===signStatus.edit)) {
            result.btnCancelReject=true;        
        }
        if (rightCancel) {
            result.btnCancel=true;
            result.btnReturn=true;
        }
    }else if (doc.status === signStatus.cancel){
        if (rightCancel) {
            result.btnResetCancel=true;
            result.btnNotRelevant=true;
        }
    }else if (doc.status === signStatus.done){
        if (rightCancel) {
            result.btnCancel=true;
        }
    }
    
    return result;
}


const mapStateToProps = (store, props) => { 
    const docData = store.docs.data[props.guid] || {};
    const route = (docData.routes || []).find(e=>e.user===store.user.data.guid) || {};
    
    const visBtn = buttonVisible(store.user.data.guid, docData, docData.routes);
    
    return {       
        docName: docData.description,
        statusDescription: docData.status_description,
        url: docData.url_view,
        lastLoad: docData.lastLoad,
        printStatus: docData.print_status,
        loading: store.loading,
        isWaitSign: route.status===1 && docData.status===1,
        isReinforced: route.type===1,
        status: route.status,        
        docStatus: docData.status,
        media: store.media,
        isAuthor: store.user.data.guid===docData.author_guid,
        routes: docData.routes || [],
        ...visBtn,
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
     loadDocument: (guid)=>dispatch(dispatchLoadDocument([guid])),
     cancelReject: (guid)=>dispatch(dispatchCancelRejectSignDocument([guid])),
     resetCancelDoc: (guid)=>dispatch(dispatchResetCancelSignDocument([guid])),
     notRelevantDoc: (guid)=>dispatch(dispatchNotRelevantSignDocument([guid])),
     setPrintStatus: (guid)=>dispatch(dispatchSetPrintStatus(guid, true)),     
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);


export const DocumentViewer=reduxConnect(props=> { 
     const [tabIndex, setTabIndex]= React.useState(0);
     const [visibleSignDoc, showSignDoc]= React.useState(false);
     const [visibleRejectDoc, showRejectDoc]= React.useState(false);
     const [visibleCancelDoc, showCancelDoc]= React.useState(false);
     const [visibleReturnDoc, showReturnDoc]= React.useState(false);
     const [visiblePrintStatus, showSavePrintStatus]= React.useState(false);
     
     
     const isMobile = props.media==='mobile' || props.media==='tablet';
     const printBtn= !isMobile;
         
     React.useEffect(()=>{
         if (!props.lastLoad) {
                props.loadDocument(props.guid)
        }else
            if (props.lastLoad.setMinutes(props.lastLoad.getMinutes() + 1)<(new Date())){
                props.loadDocument(props.guid, false)   
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [props.lastLoad])    
  
    const handleTab=(e, {activeIndex})=>{
        setTabIndex(activeIndex)
    }
     
    const handlePrint=(docName)=>{
        printCanvas(docName, ()=>{
            if (props.isAuthor && !props.printStatus && props.docStatus===signStatus.done) {
                showSavePrintStatus(true)
            }
        })
    }

    const handleSetPrintStatus=()=>{
        props.setPrintStatus(props.guid);
        showSavePrintStatus(false);
    }

    const panes = [
      { menuItem: { key: 'doc', icon: 'file', content: 'Документ' } },
      { menuItem: { key: 'sign', icon: 'certificate', content: 'Подписи' } },
    ]
    
    const signActions={
        close: ()=> {showRejectDoc(false); showSignDoc(false); showCancelDoc(false); showReturnDoc(false)},
        signDoc: ()=> showSignDoc(true),
        rejectSignDoc: ()=> showRejectDoc(true),
        cancelRejectSignDoc: ()=>props.cancelReject(props.guid),
        cancelDoc: ()=>showCancelDoc(true),
        resetCancelDoc: ()=>props.resetCancelDoc(props.guid),
        returnDoc: ()=>showReturnDoc(true),
        notRelevant: ()=>props.notRelevantDoc(props.guid),
    }
       
    const otherMenu = []
    if (props.btnReject) otherMenu.push({key:1, text: 'Отказаться от подписания', icon:<Icon name='cancel' color='red'/>, onClick: signActions.rejectSignDoc});
    if (props.btnCancelReject) otherMenu.push({key:2, text: 'Отменить отказ от подписания', 
                                            icon:<Icon name='undo' color='blue'/>, onClick: signActions.cancelRejectSignDoc});
    if (props.btnCancel) otherMenu.push({key:3, text: 'Аннулировать', 
                                            icon:<Icon name='cancel' color='red'/>, onClick: signActions.cancelDoc});                                            
    if (props.btnResetCancel) otherMenu.push({key:4, text: 'Отменить аннулирование', 
                                            icon:<Icon name='arrow alternate circle up' color='blue'/>, onClick: signActions.resetCancelDoc});
    if (props.btnReturn) otherMenu.push({key:5, text: 'Вернуть в работу', 
                                            icon:<Icon name='write square' color='blue'/>, onClick: signActions.returnDoc});
    if (props.btnNotRelevant) otherMenu.push({key:6, text: 'Сделать не актуальным', 
                                            icon:<Icon name='trash' color='red'/>, onClick: signActions.notRelevant});
    
    
    
    
    signActions.otherMenu=otherMenu;
    signActions.btnSign = props.btnSign;
    
    const stamps = props.routes.map(e=>{        
        if (e.group || !e.date) return null;
        const sign = {name: e.info.family+' '+ e.info.name+' '+ e.info.patronymic}
        if (e.sign) Object.assign(sign, e.sign)
        return {date: formatDate(e.date), ...sign}
    }).filter(e=>e)

    const padding= isMobile ? 0 : '1em';
    const style = { zIndex: 150, position: 'fixed', display: 'flex', flexFlow: 'column',
                    top: '0', bottom: padding, left: padding, right: padding}
    if (isMobile){
        style.padding =0;
        style.marginTop=0;
        style.borderRadius=0;
    }
    
    return  <><Portal
        onClose={() => props.onClose()}
        open={true} closeOnPortalMouseLeave={false} closeOnDocumentClick={false}> 
              
        <Segment  style={style}>             
            <div style={{position: 'absolute', zIndex: '10', right: isMobile ? '.2rem' : '1.2rem',top: isMobile ? '.2rem' : '1.3rem'}}>
                {printBtn && <Button size='mini' icon basic onClick={()=>handlePrint(props.docName)} style={{position: 'absolute', right: '200%'}}>
                    <Icon color='black' name='print'/>
                </Button>}
                <Button size='mini' basic icon circular onClick={()=>props.onClose()}>
                    <Icon color='red' name='close'/>
                </Button>
            </div>  
            
            
            <Tab panes={panes} onTabChange={handleTab}/>
            
            <div style={{display: (tabIndex===0 ? 'block' : 'none'), flexGrow: 1, overflow: 'auto', position: 'relative'}}>
                {props.url && <MyPDF url={props.url} media={props.media} stamps={stamps} isVoid={props.docStatus===3}/>}
            </div>
            <div style={{display: (tabIndex===1 ? 'block' : 'none'), flexGrow: 1, overflow: 'auto'}}>                
                <SignRoutes guid={props.guid} signActions={signActions}/>                
            </div>
            
           { (visibleSignDoc || visibleRejectDoc || visibleCancelDoc || visibleReturnDoc) && 
           <SignDocument guid={props.guid} reject={visibleRejectDoc} cancel={visibleCancelDoc} returnDoc={visibleReturnDoc}
                status={props.status} onClose={signActions.close}  isReinforced={props.isReinforced}/>}
        </Segment>
    </Portal>
    <Confirm open={visiblePrintStatus} 
        size='tiny'
        content='Установить статус документа "Обработан" ?'
        cancelButton='Нет'
        confirmButton='Установить'
        onCancel={()=>showSavePrintStatus(false)}   
        onConfirm={()=>handleSetPrintStatus()}/>
    </>

})