
import React from "react";
import { connect } from 'react-redux';
import { formatDateTime } from './funcDate';
import { dispatchLoadDocument } from '../store/actions';
import { Button, Icon, Modal, Divider, Tab, Segment,Header, Item, Dropdown } from 'semantic-ui-react'

const getStatusIcons=(docStatus, rowStatus)=>{
    /*sign_none = 0  # НеНачат
    sign_wait = 1  # ТребуетсяПодписание
    sign_edit = 2  # ТребуетсяУточнение
    sign_cancel = 3  # Аннулирован
    sign_done = 4  # Завершен
    sign_close = 5  # ЗакрытПринудительно*/
    
    switch (rowStatus) {
        case 1: //ТребуетсяПодписание
            switch (docStatus){
                case 2: //ТребуетсяУточнение
                    return {color:'blue', name:'pause'};
                case 3: //Аннулирован
                    return {};
                default:
                    return {color:'green', name:'clock'};
            }
        case 4: //Завершен
            return {color:'green', name:'checkmark'};
        case 5: //ЗакрытПринудительно
            return {color:'grey', name:'checkmark'};
        case 2: //ТребуетсяУточнение
            return {color:'red', name:'hand paper'};
        case 3: // Аннулирован
            return {color:'red', name:'cancel'};
        case 0: // НеНачат
            return {};
        default: // НеНачат
            return {};
        
    }
}

const readRowsToTree=(docStatus, rows, tree, list)=>{    
    const chillNodes = tree.rows
    rows.forEach(e=>{
        const newNode = {...e, rows:[]};
        newNode.sub_icon = getStatusIcons(docStatus, e.status);
        newNode.docStatus = docStatus;
        chillNodes.push(newNode);
        const childRows = list.filter(r=>r.parent===e.guid);
        readRowsToTree(docStatus, childRows, newNode, list);
    })
    chillNodes.sort((a, b)=>{
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
    })
}


const GroupNode=(props)=>{
    switch (props.name) {
        case 'queue':
            return <Header as='h3'><Icon name='tasks' size='big'/> По очереди</Header>
        case 'and':
            return <Header as='h3'><Icon name='users'/> Все</Header>
        case 'or':
            return <Header as='h3'><Icon name='user'/> Любой из</Header>
        default:
            return null
    }
}

const UserNode=(props)=>{
    const {departamen, family, name, patronymic, phone_work, position} = props.info || {};
    const fio = (family) ? family+' '+name+' '+patronymic : '<Информация отсутствует>';
    
    const isMobile = props.media==='mobile';
    const btnSize = isMobile ? 'tiny' : 'mini';
    
    const certificateColor = (props.type===1) ? 'violet' : 'olive'
    const icon=<Icon name='certificate' color={props.status===4 ? certificateColor : 'grey'}/>;
    const secondIcon = <Icon corner {...props.sub_icon} />
    
    const typeText = (props.type===1) ? 'Квалифицированная ЭП' : 'УНЭП'
    
    const buttonStyle={position: 'absolute', right: '1rem'}
    if(isMobile) {
        buttonStyle.right = '.5rem';
        buttonStyle.top = '.5rem';
    }
    const itemStyle =  isMobile ? {margin:0} : {};
    
    let signButtons=null;
    if (props.currentUser===props.user && props.status!==null){ 
         signButtons = <Button.Group  size={btnSize} style={buttonStyle} >
            <Button color='blue' onClick={() => props.signActions.signDoc()} disabled={!props.signActions.btnSign}>
                <Icon name='pencil'/>Подписать
            </Button>
            <Dropdown text={isMobile ? '...' : 'Другие действия'} floating button item 
                    options={props.signActions.otherMenu} 
                    disabled={props.signActions.otherMenu.length===0}/>
        </Button.Group>
    }    
    return <Item.Group>
    <Item style={itemStyle}>
        <div><Icon.Group size={isMobile ? 'large' : 'big'}>{icon}{secondIcon}</Icon.Group></div>
        <Item.Content>
            <Item.Header as='h4' style={{fontSize: '1em'}}> {fio}{signButtons}</Item.Header>            
            {(departamen || position )&& <Item.Description style={{fontSize: '0.9em'}}>{departamen || ''}, {position || ''}</Item.Description>}
            <Item.Extra style={{fontSize: '0.9em'}}>
                {typeText} 
                {props.date && props.status===4 && <span className='blue'> {formatDateTime(props.date+'Z', false)}</span>}
            </Item.Extra>
        </Item.Content>
    </Item>    
    
    </Item.Group>   
}

const SignNode = (props)=>{
    const isChild = props.data.rows.length>0
    
    const nodes = props.data.rows.map(e=>{
         return <SignNode key={e.guid} user={props.user} data={{...e}} signActions={props.signActions} media={props.media}/>
    })
  
    const tertiary = !props.data.group && !props.data.info;
    const secondary = !props.data.group && !tertiary && props.data.status===null;

    let curentNode = null;
    if (props.data.group) curentNode = <GroupNode name={props.data.group} />
        else curentNode = <UserNode  {...props.data} currentUser={props.user} signActions={props.signActions} media={props.media}/>
  
    return <Segment tertiary={tertiary} secondary={secondary} style={{ marginTop: 0}}>
        {curentNode}
    {isChild && <Segment.Group>
        {nodes}
    </Segment.Group>}
    </Segment>
}


const routesTree=(docStatus, list)=>{    
    const result = {rows:[]};
    const emptyId="00000000-0000-0000-0000-000000000000";
    const rootRows=list.filter(e=>e.parent===emptyId)
    readRowsToTree(docStatus, rootRows, result, list);
    return result
}

const mapStateToProps = (store, props) => { 
    const docData = store.docs.data[props.guid] || {};    
    return {       
        loading: store.loading,
        tree: routesTree(docData.status, docData.routes || []),
        user: store.user.data.guid,
        statusDescription: docData.status_description,
        docStatus: docData.status,
        actual: docData.actual,
        media: store.media,
    }
}


const mapDispatchToProps = (dispatch, props) => {
  return {
      loadDocument: ()=>dispatch(dispatchLoadDocument([props.guid]))
  };
};


const reduxConnect = connect(mapStateToProps, mapDispatchToProps);

export const SignRoutes=reduxConnect(props=> { 
    //console.log(props.media)
    
    let descrStatus = null
    if (!props.actual===3){
        descrStatus = 'Не актуальный'
    }else if (props.docStatus===3){
        descrStatus = 'Аннулирован'
    }else if (props.docStatus===2){
        descrStatus = 'Отказ пописания'
    }
    
    
    const nodes = props.tree.rows.map(e=>{
            return <SignNode key={e.guid} user={props.user} data={{...e}} signActions={props.signActions} media={props.media}/>
    })
    
    return <Segment basic style={{padding: 0, marginTop: '-2px', height: '100%', display: 'flex', flexFlow: 'column'}}>
        {props.statusDescription && 
        <Segment basic style={{marginBottom: 0}}>
            {descrStatus && <Header as='h4' color='red' content={descrStatus}/>}
            <Header as='h5' content={props.statusDescription} style={{marginTop: '0'}}/>
        </Segment>}
        <Segment style={{flexGrow: '1' , marginTop: 0}}>
            <div style={{position: 'absolute', zIndex: '10',right: '1.2rem',top: '1.3rem'}}>
                <Button size='tiny' basic icon circular onClick={props.loadDocument}>
                    <Icon color='green' name='sync'/>
                </Button>
            </div>
            {nodes}
        </Segment>
    </Segment>
})