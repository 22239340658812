import React from 'react'

import { Input, Button, Icon} from 'semantic-ui-react'

const keyboard = [
    [7, 8, 9], 
    [4, 5, 6], 
    [1, 2, 3], 
    ['Escape', 0, 'Backspace']
]

const KeyButtons =(props)=>{

     const buttons = keyboard.map((k, i)=>{
        const row = k.map(r=>{
            let content=r;
            let isIcon=false;
            if (r==='Escape') {isIcon=true; content=<Icon name='undo'/>}
            if (r==='Backspace') {isIcon=true; content=<Icon name='long arrow alternate left'/>}
            
            return <Button fluid key={'btn'+r} size='massive' name={'btn_'+r} 
                    icon={isIcon} style={{padding:null, margin: '0.13em' }}
                    onClick={()=>props.onClick(r)}>
                    {content}
                </Button>            
        })
        return <div key={'row'+i} style={{display: 'flex', flexFlow: 'row'}}>
                {row}
            </div>
        
    })
    return <div style={{display: 'flex', flexFlow: 'column'}}>{buttons}</div>
}

export const PinInput=(props, refer)=>{
    const {
        digits,
        onChange,
        error,
        password,
        firstInputRef,
        onSuccess,        
    } = props;
    
    const onCancel = props.onCancel || (()=>null);
    
    const length = digits.length;
    // здесь будут ссылки на input-элементы
    const inputRefs = React.useRef([]);
    
    // при монтировании компонента фокус на первое поле
    React.useEffect(() => {
        inputRefs.current[0].focus();
        firstInputRef.current = inputRefs.current[0];
    }, []);

    // поля подсвечиваем красным (ошибка) или зеленым
    React.useEffect(() => {
        digits.forEach((value, index) => {
            let color = '#dfd'
            if (!value) color = '#eee'
            else if (error || !value.match(/^[0-9]$/))  color = '#fdd'
            inputRefs.current[index].style.backgroundColor = color;
        });
    }, [digits]);

    const handleSuccess=(value)=>{
        if (!onSuccess) return;
        if (value.findIndex(e=>e==='')===-1) onSuccess(value)
    }

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
            event.preventDefault();
            if (digits[index].match(/^[0-9]$/)) {
                // если элемент массива digits содержит цифру, то при нажатии клавиши
                // вызываем callback-функцию родителя, чтобы записать пустую строку
                const newDigits = [...digits]; // копия digits
                newDigits[index] = '';
                onChange(newDigits);
                handleSuccess(newDigits);
            } else {
                // элемент массива digits пустой, удалять нечего — так что надо сместить
                // фокус на предыдущее поле input — при условии, что это не первое поле
                if (index > 0) inputRefs.current[index - 1].focus();
            }
        }
    }

    const handleChange = (index, newValue) => {
        const oldDigit = digits[index];
        // старую цифру в поле ввода убираем, оставляя только новую
        const newDigit = newValue.trim().replace(oldDigit, '');
        // если это не цифра, ничего не делаем, пока не будет цифры
        if (newDigit < '0' || newDigit > '9') return;
        // теперь вызываем callback родителя, чтобы обовить digits
        const newDigits = [...digits]; // копия digits
        newDigits[index] = newDigit;
        onChange(newDigits);
        handleSuccess(newDigits);
        // смещаем фокус на следующее поле для ввода следующей цифры
        if (index < length - 1) {
            inputRefs.current[index + 1].focus();
        } else { // или убираем фокус, если это было последнее поле
            inputRefs.current[index].blur();
        }
    }
    
    const handleClickKeyboard=(value)=>{
        if (value==='Escape'){
            onChange(digits.map(e=>''));
            return
        }
        let index = -1;
        digits.forEach((e,i)=>{
            if (e) index=i;
        });
        const newDigits = [...digits]; // копия digits
        if (value==='Backspace'){
            onChange(digits.map(e=>''));            
            newDigits[index] = '';
            onChange(newDigits);
            return
        }        
        newDigits[index+1] = String(value);
        onChange(newDigits);
        if (index+1===digits.length-1) {
            console.log(newDigits)
            handleSuccess(newDigits);
        }
    }
       
    return (
        <div>
            <div style={{display: 'flex'}}>
                {digits.map((digit, index) => (
                    <input autoComplete="new-password"
                        readOnly
                        name={"fakename"+index}
                        key={index}
                        type={password ? 'password' : 'tel'}
                        className='pin-input'
                        value={digit}
                        onChange={event => handleChange(index, event.target.value)}
                        onKeyDown={event => handleKeyDown(index, event)}
                        ref={element => inputRefs.current[index] = element}
                    />
                ))}
            </div>
            <KeyButtons onClick={handleClickKeyboard}/>
        </div>
    );
}